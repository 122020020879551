<template>
  <div :key="formKey" v-if="calculation" class="loanCalculator" ref="calculator">
    <span>
      {{ t.customizeLoan ?? 't.customizeLoan' }}
    </span>
    <div>
      <Loader class="loading" v-if="isCalculating || loading" />
      <div class="loanParameters">
        <div class="financingProviders" v-if="listingId && loanCalculations?.filter(lc => lc.config?.provider?.name)?.length > 1">
          {{ t.financingProvidersTitle ?? 't.financingProvidersTitle' }}
          <div>
            <div :class="{ financingProvider: true, selected: item?.config?.provider?.name === config?.provider?.name }" v-for="item in loanCalculations" :key="item?.config?.provider?.name" @click="handleLoanProviderChange(item?.config?.provider)">{{ t[item?.config?.provider?.name] }}</div>
          </div>
        </div>
        <div v-if="requireNationality" class="nationality">
          {{ t.nationality }}
          <SearchDropdown :multi="false" name="countryDropdown" data-test-id="countryDropdown" v-model="selectedCountryId" :options="getLookupFieldValues()" :placeholder="t.selectCountry ?? 't.selectCountry'" @update:model-value="onCountrySelected" />
        </div>
        <div class="downPayment">
          {{ t.downPayment ?? 't.downPayment' }}
          <label>
            <input @keydown="acceptNumber" type="text" :class="downPaymentError ? 'downPaymentError' : ''" :value="downPaymentInput" @input="onDownPaymentInput" data-test-id="downPayment" />
            <span class="currency">{{ getSymbol(currency) }}</span>
          </label>
          <button @click="handleDownPaymentChange(calculation.downPayment)">{{ t.calculate ?? 't.calculate' }}</button>
          <div class="downPaymentExtraInfo">
            <InfoIcon />
            <span>{{ t.downPaymentExtraInfo ?? 't.downPaymentExtraInfo' }}</span>
          </div>
          <span class="error" v-if="downPaymentError">
            <span><InfoIcon /></span>
            {{ downPaymentError }}
          </span>
        </div>
        <div v-if="config?.terms" class="paymentTerms">
          {{ t.paymentTerms ?? 't.paymentTerms' }}
          <div>
            <button v-for="term in config.terms" :key="term" @click="handleTermChange(term)" :data-test-id="term" :class="calculation.paymentTerms === term ? 'selected' : ''">
              {{ term }}
            </button>
          </div>
          <div class="paymentTermsExtraInfo">
            <InfoIcon />
            <span>{{ t.paymentTermsExtraInfo ?? 't.paymentTermsExtraInfo' }}</span>
          </div>
        </div>
        <div v-if="config?.interestTypes?.length >= 1" class="interestType">
          {{ t.interestType ?? 't.interestType' }}
          <div>
            <button v-for="interestType in config.interestTypes" :key="interestType" @click="handleInterestTypeChange(interestType)" :data-test-id="interestType" :class="calculation.interestType === interestType ? 'selected' : ''">
              {{ t[interestType] ?? interestType }}
            </button>
          </div>
        </div>
        <button v-if="orderId" @click="saveCalculation" :disabled="errors?.length" class="saveCalculationButton">
          {{ t.saveCalculation ?? 't.saveCalculation' }}
        </button>
        <div v-if="errors?.length" class="calculationErrors">
          <span v-for="error of errors" :key="error" class="calculationError">
            <InfoIcon />
            <span> {{ (t[error.field] ?? error.field) + ' ' + (t[error.errorCode] ?? error.errorCode) + '\n' }}</span>
            <!-- <span> {{ (t[error.field] ?? `t.${error.field}`) + ' ' + (t[error.errorCode.replaceAll(' ', '')] ?? `t.${error.errorCode.replaceAll(' ', '')}`) + '\n' }}</span> -->
          </span>
        </div>
      </div>
      <div class="loanOverview" v-if="config?.provider?.name !== 'santanderFlex' && config?.provider?.name !== 'santanderBalloon'">
        <div class="details">
          <span class="santanderTitle">{{ t.santanderTitle ?? 't.santanderTitle' }}</span>
          <div class="bag_terminalen" v-if="config?.provider?.name === 'bmw' || config?.provider?.name === 'jyske'">
            <div class="bag_terminalen_title">
              {{ t.bag_terminalen_title ?? 't.bag_terminalen_title' }}
            </div>
            <div class="bag_terminalen_price">
              {{ `${formatNumber(calculation.monthlyPayment, 2, currency)} x ${calculation.paymentTerms} ${t.months ?? 't.months'}` }}
            </div>
          </div>
          <div class="monthlyPayment">
            <span>{{ t.monthlyPaymentDetail ?? 't.monthlyPaymentDetail' }}</span>
            <span>{{ calculation?.monthlyPayment ? `${formatNumber(calculation.monthlyPayment, 2, currency)}${t.perMonth ? ` / ${t.perMonth}` : ''}` : ' - ' }}</span>
          </div>
          <div class="paymentTerms">
            <span>{{ t.paymentTermsDetail ?? 't.paymentTermsDetail' }}</span>
            <span>{{ calculation?.paymentTerms ? `${calculation.paymentTerms} ${t.months ?? 't.months'}` : ' - ' }}</span>
          </div>
          <div class="downPayment">
            <span>{{ t.downPaymentDetail ?? 't.downPaymentDetail' }}</span>
            <span>{{ calculation?.downPayment ? formatNumber(calculation.downPayment, 2, currency) : ' - ' }}</span>
          </div>
          <div class="loanAmount">
            <span>{{ t.loanAmountDetail ?? 't.loanAmountDetail' }}</span>
            <span>{{ calculation?.loanAmount ? formatNumber(calculation.loanAmount, 2, currency) : ' - ' }}</span>
          </div>
          <div class="totalFinanceAmount">
            <span>{{ t.totalFinanceAmountDetail ?? 't.totalFinanceAmountDetail' }}</span>
            <span>{{ calculation?.financedAmount ? formatNumber(calculation.financedAmount, 2, currency) : ' - ' }}</span>
          </div>
          <div class="totalPayable">
            <span>{{ t.totalPayableDetail ?? 't.totalPayableDetail' }}</span>
            <span>{{ calculation?.totalPayable ? formatNumber(calculation.totalPayable, 2, currency) : ' - ' }}</span>
          </div>
          <div class="aopBeforeTax">
            <span>{{ t.aopBeforeTaxDetail ?? 't.aopBeforeTaxDetail' }}</span>
            <span>{{ calculation.aopBeforeTax ? `${calculation.aopBeforeTax} %` : ' - ' }}</span>
          </div>
          <div class="apr">
            <span>{{ t.aprDetail ?? 't.aprDetail' }}</span>
            <span>{{ calculation?.apr ? `${calculation?.apr} %` : ' - ' }}</span>
          </div>
          <div class="rates">
            <div v-for="(rate, key) in rates" :key="key">
              <div>{{ t[rate?.key] ?? rate?.key }} {{ Number(rate?.value) }}%</div>
            </div>
          </div>
          <div class="disclaimer" v-if="calculation?.disclaimer">
            <span v-html="t.disclaimer ?? 't.disclaimer'" />
            <p v-html="calculation.disclaimer" />
          </div>
          <div class="financingLogo" v-if="config?.provider?.logo">
            <span>{{ t.financingFrom ?? 't.financingFrom' }}</span>
            <img :src="imageUrl" />
          </div>
        </div>
        <button v-if="listingId" class="startOrderButton" :disabled="startOrderDisabled" data-test-id="startOrderCalculator" @click="startOrder">{{ t.startOrder ?? 't.startOrder' }}</button>
      </div>
      <div class="loanOverview santanderFlex" v-else-if="config?.provider?.name === 'santanderFlex'">
        <div class="details">
          <span class="santanderTitle">{{ t.santanderFlexTitle ?? 't.santanderFlexTitle' }}</span>
          <div v-for="(option, index) in flexCalculator" :key="index" class="installmentPlan">
            <span class="installmentPlanTitle">{{ t[`installmentPlan${index + 1}`] ?? `t.installmentPlan${index + 1}` }} </span>
            <div>
              <span>{{ t.monthlyPayment ?? 't.monthlyPayment' }}</span>
              <span>{{ formatNumber(option.importoRata, 2, currency) }}</span>
            </div>
            <div>
              <span>{{ t.paymentTerms ?? 't.paymentTerms' }}</span>
              <span>{{ paymentTermCalculatorFlex(option) }}</span>
            </div>
            <div>
              <span>{{ t.aopBeforeTax ?? 't.aopBeforeTax' }}</span>
              <span>{{ option?.tan ?? 0 }} %</span>
            </div>
            <div>
              <span>{{ t.apr ?? 't.apr' }}</span>
              <span>{{ option?.taeg ?? 0 }} %</span>
            </div>
            <div class="afterMaxi" v-if="index !== flexCalculator.length - 1">
              <div class="after">{{ t.after ?? 't.after' }} {{ paymentTermCalculatorFlex(option) }}</div>
              <div class="maxiRata">
                <div>{{ t.maxiRata ?? 't.maxiRata' }}</div>
                <div>{{ formatNumber(option?.capitaleResiduo, 2, currency) }}</div>
              </div>
            </div>
            <div class="hr" />
          </div>
          <div v-if="calculation.disclaimer" class="disclaimer">
            <span v-html="t.disclaimer ?? 't.disclaimer'" />
            <p v-html="calculation.disclaimer ?? ' - '" />
          </div>
          <div class="financingLogo" v-if="config.provider.logo">
            <span>{{ t.financingFrom ?? 't.financingFrom' }}</span>
            <img :src="imageUrl" />
          </div>
        </div>
        <button v-if="listingId" class="startOrderButton" :disabled="startOrderDisabled" data-test-id="startOrderCalculator" @click="startOrder">{{ t.startOrder ?? 't.startOrder' }}</button>
      </div>
      <div class="loanOverview santanderBalloon" v-else-if="config?.provider?.name === 'santanderBalloon'">
        <div class="details">
          <span class="santanderTitle">{{ t.santanderBalloonTitle ?? 't.santanderBalloonTitle' }}</span>
          <div class="installmentPlan">
            <div>
              <span>{{ t.monthlyPayment ?? 't.monthlyPayment' }}</span>
              <span>{{ calculation.monthlyPayment ? formatNumber(calculation.monthlyPayment, 2, currency) : ' - ' }}</span>
            </div>
            <div>
              <span>{{ t.paymentTerms ?? 't.paymentTerms' }}</span>
              <span>{{ `${calculation.paymentTerms} ${t.months ?? 't.months'}` }}</span>
            </div>
            <div>
              <span>{{ t.aopBeforeTax ?? 't.aopBeforeTax' }}</span>
              <span>{{ calculation.aopBeforeTax }}%</span>
            </div>
            <div>
              <span>{{ t.apr ?? 't.apr' }}</span>
              <span>{{ calculation.apr }}%</span>
            </div>
            <br v-if="calculation.paymentTerms" />
            <div v-if="calculation.paymentTerms" class="afterMaxi">
              <div v-if="calculation.paymentTerms" class="after">{{ t.after ?? 't.after' }} {{ calculation.paymentTerms }} {{ t.months ?? 't.months' }}</div>
              <div v-if="calculation.paymentTerms" class="maxiRata">
                <div>{{ t[balloonCalculator?.key] ?? `t.${balloonCalculator?.key}` }}</div>
                <div>{{ balloonCalculator?.value ? formatNumber(balloonCalculator.value, 2, currency) : ' - ' }}</div>
              </div>
            </div>
          </div>
          <div v-if="calculation.disclaimer" class="disclaimer">
            <span v-html="t.disclaimer ?? 't.disclaimer'" />
            <p v-html="calculation.disclaimer ?? ' - '" />
          </div>
          <div class="financingLogo" v-if="config.provider.logo">
            <span>{{ t.financingFrom ?? 't.financingFrom' }}</span>
            <img :src="imageUrl" />
          </div>
        </div>
        <button v-if="listingId" class="startOrderButton" :disabled="startOrderDisabled" data-test-id="startOrderCalculator" @click="startOrder">{{ t.startOrder ?? 't.startOrder' }}</button>
      </div>
    </div>
    <div v-if="showCalculationDetails" class="loanDetailsAccordion">
      <div class="loanDetailsAccordionHeader">
        <span>{{ t.loanDetailsFull ?? 't.loanDetailsFull' }}</span>
        <button @click="expandLoanDetailsAccordion = !expandLoanDetailsAccordion">{{ expandLoanDetailsAccordion ? (t.hideLoanDetails ?? 't.hideLoanDetails') : (t.showLoanDetails ?? 't.showLoanDetails') }}</button>
      </div>
      <transition>
        <dl v-if="expandLoanDetailsAccordion" class="loanDetailsTable">
          <div v-for="detail in loanAccordionData" :key="detail.label" :class="detail.label">
            <dt>{{ t[detail.label] ?? `t.${detail.label}` }}</dt>
            <dd>{{ t[detail.value] ?? detail.value }}</dd>
          </div>
        </dl>
      </transition>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { analyticsMixin } from '../../analytics.js'
import { langMixin } from '../lang'
import Loader from '@/components/Loader.ce.vue'
import InfoIcon from '../../assets/info-icon.svg'
import SearchDropdown from '../SearchDropdown.ce.vue'

export default {
  name: 'SeezLoanCalculator',
  components: { Loader, InfoIcon, SearchDropdown },
  mixins: [SeezSdk.vueQueryMixin, langMixin('FINANCING_PROVIDER_COMPONENT_TRANSLATIONS'), analyticsMixin],
  props: {
    orderId: { type: String, default: null },
    listingId: { type: String, default: null },
    financingOption: { type: Object, default: null },
    currency: { type: String, default: 'EUR' },
    rates: { type: Object, default: null },
    loading: { type: Boolean, default: false },
    providerLogo: { type: String, default: null },
    requireNationality: { type: Boolean, default: false },
    showCalculationDetails: { type: Boolean, default: false }
  },
  emits: ['recalculate', 'saveCalculation', 'startOrder', 'defaultFinancing'],
  data() {
    return {
      formKey: 1,
      isCalculating: false,
      calculation: { ...this.financingOption?.calculation },
      loanCalculations: null,
      config: { ...this.financingOption?.config },
      errors: this.financingOption?.errors,
      downPaymentError: null,
      flexCalculator: null,
      balloonCalculator: null,
      selectedCountryId: this.requireNationality ? 'QA' : null,
      lastCalculatedCountryId: '',
      expandLoanDetailsAccordion: true,
      downPaymentInput: ''
    }
  },
  computed: {
    calculationQuery() {
      return 'apr aopBeforeTax financedAmount loanAmount downPayment downPaymentPct totalPayable totalLoanCost paymentTerms monthlyPayment nominalInterestRate interestType interestRate disclaimer rates { key value } customAttributes { key value }'
    },
    loanAccordionData() {
      if (this.calculation) {
        const numberFields = ['loanAmount', 'downPayment', 'financedAmount', 'loanAmount', 'monthlyPayment', 'totalLoanCost', 'totalPayable']
        const percentageFields = ['apr', 'aopBeforeTax', 'interestRate', 'downPaymentPct', 'nominalInterestRate']
        const otherAcceptedFields = ['paymentTerms', 'interestType']
        const calculationDetails = []
        Object.keys(this.calculation).forEach(detail => {
          let value
          if (numberFields.includes(detail)) value = this.formatNumber(this.calculation[detail], 2, this.currency)
          else if (percentageFields.includes(detail)) value = `${this.calculation[detail]} %`
          else if (otherAcceptedFields.includes(detail)) value = this.calculation[detail]

          if (value) calculationDetails.push({ label: `${detail}Detail`, value: value })
        })
        return calculationDetails
      }
      return {}
    },
    imageUrl() {
      return `${import.meta.env.VITE_SEEZ_BASE_URL}${this.providerLogo ?? this.config?.provider.logo}`
    }
  },
  watch: {
    calculation: {
      handler: function () {
        this.handleFlexBalloonValue()
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.debounceTimer)
  },
  async mounted() {
    this.handleFlexBalloonValue()
    await this.loanCalculation()
    if (window.location.hash === '#calc') {
      this.$refs.calculator.scrollIntoView(true, { behavior: 'smooth' })
    }
  },
  methods: {
    handleFlexBalloonValue() {
      if (this.config?.provider?.name === 'santanderFlex') {
        const flexCalculator = this.calculation?.customAttributes?.[0]?.value
        this.flexCalculator = JSON.parse(flexCalculator)
      } else if (this.config?.provider?.name === 'santanderBalloon') {
        this.balloonCalculator = this?.calculation?.customAttributes?.[0]
      }
    },
    paymentTermCalculatorFlex(option) {
      if (option.rataADifference) {
        return `${option.rataADifference} ${this.t.months}`
      }
      return ' - '
    },
    checkDownPayment(downPayment) {
      const downPaymentValue = this.financingOption?.config?.downPayment ?? this.config?.downPayment
      if (downPayment == 0) {
        this.downPaymentError = this.t.downPaymentNotZero
        return true
      }
      if (downPayment < downPaymentValue?.min) {
        this.downPaymentError = this.t.minimumDownPayment.replace('{{value}}', `${this.formatNumber(downPaymentValue?.min, 2, this.currency)}`)
        return true
      } else if (downPayment > downPaymentValue?.max) {
        this.downPaymentError = this.t.maximumDownPayment.replace('{{value}}', `${this.formatNumber(downPaymentValue?.max, 2, this.currency)}`)
        return true
      }
      this.downPaymentError = null
      this.calculation.downPayment = downPayment
    },
    formatOnInput(numberVal) {
      return new Intl.NumberFormat(this.locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(numberVal)
    },
    parseLocaleNumber(inputValue, locale) {
      if (!inputValue) return 0

      const { thousandSep, decimalSep } = this.getLocaleSeparators(locale)

      let result = inputValue
      const reThousand = new RegExp(`\\${thousandSep}`, 'g')
      result = result.replace(reThousand, '')

      if (decimalSep !== '.') {
        const reDecimal = new RegExp(`\\${decimalSep}`, 'g')
        result = result.replace(reDecimal, '.')
      }

      return parseFloat(result)
    },
    getLocaleSeparators(locale) {
      // Format '1000.1' in the user’s locale => e.g. "1,000.1" (en-US) or "1.000,1" (da-DK)
      const formatted = (1000.1).toLocaleString(locale)
      const onlyPunctuation = formatted.replace(/\d/g, '')
      const thousandSep = onlyPunctuation.charAt(0)
      const decimalSep = onlyPunctuation.charAt(1)
      return { thousandSep, decimalSep }
    },

    onDownPaymentInput(evt) {
      let raw = evt.target.value
      // Keep only digits, plus comma and dot
      raw = raw.replace(/[^\d.,]/g, '')
      const parsed = this.parseLocaleNumber(raw, this.locale)
      if (!isNaN(parsed)) {
        this.calculation.downPayment = parsed
      } else {
        this.calculation.downPayment = 0
      }
      if (this.calculation.downPayment === 0) {
        this.downPaymentInput = ''
        evt.target.value = ''
      } else {
        const maskedValue = this.formatOnInput(this.calculation.downPayment)
        this.downPaymentInput = maskedValue
        evt.target.value = maskedValue
      }
    },
    handleLoanProviderChange(provider) {
      this.config.provider = provider
      this.loanCalculation()
    },
    handleDownPaymentChange(downPayment) {
      if (!this.checkDownPayment(downPayment)) {
        this.loanCalculation()
      }
    },
    handleInterestTypeChange(interestType) {
      if (!this.checkDownPayment(this.calculation.downPayment)) {
        this.calculation.interestType = interestType
        this.loanCalculation()
      }
    },
    handleTermChange(term) {
      if (!this.checkDownPayment(this.calculation.downPayment)) {
        this.calculation.paymentTerms = term
        this.loanCalculation()
      }
    },
    acceptNumber(evt) {
      return ['e', 'E', '+', ' - '].includes(evt.key) && evt.preventDefault()
    },
    async saveCalculation() {
      this.$emit('saveCalculation', this.calculation)
    },
    async startOrder() {
      this.$emit('startOrder', { calculations: { ...(this.loanCalculations ?? null) } })
    },
    async loanCalculation() {
      const financingInput = {
        selectedProvider: this.config?.provider?.name,
        downPayment: Number(this.calculation.downPayment),
        paymentTerms: this.calculation.paymentTerms,
        interestType: !this.order?.payment?.financing?.config?.interestTypes?.length && !this.config?.interestTypes?.length ? undefined : this.calculation.interestType
      }

      if (this.requireNationality) {
        financingInput.customerType = this.selectedCountryId === 'QA' ? 'qatari' : 'expat'
      }

      try {
        this.isCalculating = true

        const input = { input: financingInput }

        const query = `
          query loanCalculations($input: LoanCalculationInput) {
            loanCalculations(
              ${this.orderId ? `orderId: ${this.orderId}` : ''}
              ${this.listingId ? `listingId: ${this.listingId}` : ''}
              input: $input
            ) {
              selected
              config { interestTypes terms maxPaymentTerms downPayment { min max default } provider { name logo }}
              userInput { selectedProvider downPayment interestType paymentTerms }
              calculation {${this.calculationQuery}}
              errors { field errorCode }
            }
          }
        `

        const { loanCalculations } = await this.queryApi(query, input)
        this.loanCalculations = loanCalculations
        const loanCalculation = loanCalculations?.find(lc => lc.selected === true) ?? (loanCalculations?.length ? loanCalculations?.[0] : null)

        if (loanCalculation) {
          if (loanCalculation.calculation) this.calculation = loanCalculation.calculation

          if (loanCalculation.config.maxPaymentTerms) {
            let newTerms = loanCalculation.config.terms.filter(f => f < loanCalculation.config.maxPaymentTerms)
            if (!newTerms.includes(loanCalculation.config.maxPaymentTerms)) newTerms = [...newTerms, loanCalculation.config.maxPaymentTerms]
            loanCalculation.config.terms = newTerms
          }

          // Initialize the real-time text from the raw numeric value
          this.downPaymentInput = this.calculation.downPayment ? this.formatOnInput(this.calculation.downPayment) : ''

          this.config = loanCalculation.config
          this.errors = loanCalculation.errors
          this.$emit('defaultFinancing', this.calculation)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isCalculating = false
      }
    },
    // Nationality specific methods
    getLookupFieldValues() {
      let field = {}
      field.lookupGeneratorFunction = 'lookupGeneratorFunction'
      if (field.lookupGeneratorFunction && window.generateNationalities) {
        const vals = window.generateNationalities()
        return vals
      }
      return field.values ?? []
    },
    onCountrySelected(countryId) {
      if (this.lastCalculatedCountryId === countryId) return
      this.loanCalculation()
    }
  }
}
</script>

<style lang="scss">
.loanCalculator {
  position: relative;
  text-align: start;
}
</style>
