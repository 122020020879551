const transformThemeStringToObject = inputString => {
  try {
    const cleanedString = inputString
      .replace(/(\r\n|\n|\r)/gm, '') // Remove line breaks
      .replace(/\s+/g, ' ') // Remove extra spaces

    const transformedObject = JSON.parse(cleanedString)
    return transformedObject
  } catch (error) {
    console.error('Error transforming string to object:', error)
    return null
  }
}
export const handleThemeStringToObject = chatConfigResponse => {
  const themeObject = transformThemeStringToObject(chatConfigResponse)
  const newChatConfigWithObject = themeObject

  return newChatConfigWithObject
}

export const hexToRgba = (hex = '', opacity) => {
  if (hex == null) return 'rgba(0, 0, 0, 0)'

  if (hex.startsWith('#')) {
    hex = hex.slice(1)
  }

  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export const getNewColorFromHex = (hex = '', opacity = 0, mixHex = '5', mixRatio = '0.5') => {
  if (hex === 'Transparent') return 'rgba(45, 45, 45, 1)'

  hex = hex.replace(/^#/, '')
  mixHex = mixHex.replace(/^#/, '')

  const hexBigint = parseInt(hex, 16)
  const mixBigint = parseInt(mixHex, 16)

  const hexRed = (hexBigint >> 16) & 255
  const hexGreen = (hexBigint >> 8) & 255
  const hexBlue = hexBigint & 255

  const mixRed = (mixBigint >> 16) & 255
  const mixGreen = (mixBigint >> 8) & 255
  const mixBlue = mixBigint & 255

  const mixedRed = Math.round(hexRed * (1 - mixRatio) + mixRed * mixRatio)
  const mixedGreen = Math.round(hexGreen * (1 - mixRatio) + mixGreen * mixRatio)
  const mixedBlue = Math.round(hexBlue * (1 - mixRatio) + mixBlue * mixRatio)

  return `rgba(${mixedRed}, ${mixedGreen}, ${mixedBlue}, ${opacity})`
}

export const isColorWhite = (value = '') => {
  if (!value) return false

  const whiteColorRegex = /^(#fff(f{3})?|rgb(a?)\(\s*255\s*,\s*255\s*,\s*255\s*(,\s*\d+(\.\d+)?\s*)?\))$/i
  return whiteColorRegex.test(value)
}

export const isColorWhiteOrTransparent = color => {
  if (isColorWhite(color) || color === 'Transparent' || !color)
    return true

  return false
}
