<template>
  <div @click="triggerFileInput" class="fileUploader">
    <div class="dragDropArea" @dragover.prevent="handleDragOver" @dragenter.prevent @drop.prevent="handleFileDrop($event)">
      <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5625 2.8125C10.875 2.03125 9.875 1.5 8.75 1.5C6.65625 1.5 5 3.1875 5 5.25V5.28125C5 5.90625 4.59375 6.46875 4 6.6875C2.8125 7.09375 2 8.21875 2 9.5C2 11.1562 3.34375 12.5 5 12.5H16.25H16.3438C16.3438 12.5 16.375 12.5 16.4062 12.5C17.8438 12.4375 19 11.2188 19 9.75C19 8.625 18.3125 7.65625 17.3438 7.25C16.7188 6.96875 16.3438 6.3125 16.4688 5.625C16.4688 5.5 16.5 5.375 16.5 5.25C16.5 4.03125 15.4688 3 14.25 3C13.9062 3 13.5938 3.09375 13.3125 3.21875C12.7188 3.46875 12 3.3125 11.5625 2.8125ZM16.5 14H16.25H15H5C2.5 14 0.5 12 0.5 9.5C0.5 7.5625 1.75 5.875 3.5 5.28125V5.25C3.5 2.375 5.84375 0 8.75 0C10.3125 0 11.75 0.71875 12.7188 1.84375C13.1875 1.625 13.6875 1.5 14.25 1.5C16.3125 1.5 18 3.1875 18 5.25C18 5.46875 17.9688 5.65625 17.9375 5.875C19.4375 6.53125 20.5 8.03125 20.5 9.75C20.5 12.0312 18.7188 13.875 16.5 14ZM7.46875 6.96875H7.4375L9.9375 4.46875C10.25 4.1875 10.7188 4.1875 11 4.46875L13.5 6.96875C13.8125 7.28125 13.8125 7.75 13.5 8.03125C13.2188 8.34375 12.75 8.34375 12.4375 8.03125L11.2188 6.8125V11C11.2188 11.4375 10.9062 11.75 10.4688 11.75C10.0625 11.75 9.71875 11.4375 9.71875 11V6.8125L8.5 8.03125C8.21875 8.34375 7.75 8.34375 7.46875 8.03125C7.15625 7.75 7.15625 7.28125 7.46875 6.96875Z" fill="black" />
      </svg>

      <p>
        {{ t.dragDropLabel }}
        <span class="clickable">{{ t.clickSelect }}</span>
      </p>
      <input type="file" ref="fileInput" :accept="acceptedFiles" @change="handleFileChange" :multiple="allowMultiple" style="display: none" />
    </div>
    <div v-if="uploadedFiles.length" class="filesGrid">
      <div class="fileItem" v-for="(file, index) in uploadedFiles" :key="index">
        <img v-if="isImage(file)" :src="file.preview" alt="Uploaded File" />
        <div v-else class="nonImage">{{ file.name }}</div>
        <button type="button" @click.stop="deleteFile(index)"><CloseIcon /></button>
      </div>
    </div>
  </div>
</template>

<script>
import { langMixin } from './lang'
import CloseIcon from '../assets/circle-close.svg'

export default {
  name: 'FileUploader',
  components: { CloseIcon },
  mixins: [langMixin('fileUploader')],
  props: {
    acceptedFiles: { type: String, default: '.jpg,.jpeg,.png,.pdf' },
    allowMultiple: { type: Boolean, default: false }
  },
  emits: ['updated'],
  data() {
    return {
      uploadedFiles: []
    }
  },
  methods: {
    handleDragOver(e) {
      e.dataTransfer.dropEffect = 'copy'
    },
    handleFileDrop(e) {
      const files = Array.from(e.dataTransfer.files)
      this.processFiles(files)
    },
    handleFileChange(e) {
      const files = Array.from(e.target.files)
      this.processFiles(files)
      e.target.value = ''
    },
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    processFiles(files) {
      if (!this.allowMultiple) {
        this.uploadedFiles = []
        const file = files[0]
        if (this.isImage(file)) {
          file.preview = URL.createObjectURL(file)
        }
        this.uploadedFiles.push(file)
      } else {
        files.forEach(file => {
          if (this.isImage(file)) {
            file.preview = URL.createObjectURL(file)
          }
          this.uploadedFiles.push(file)
        })
      }
      this.$emit('updated', this.uploadedFiles)
    },
    isImage(file) {
      return file.type && file.type.startsWith('image')
    },
    deleteFile(index) {
      const file = this.uploadedFiles[index]
      if (file.preview) {
        URL.revokeObjectURL(file.preview)
      }
      this.uploadedFiles.splice(index, 1)
      this.$emit('updated', this.uploadedFiles)
    }
  }
}
</script>

<style>
.fileUploader {
  .dragDropArea {
    border: 0.125rem dashed #ccc;
    border-radius: 0.375rem;
    padding: 1.25rem;
    text-align: center;
    transition: background-color 0.2s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
  }

  .dragDropArea:hover {
    background-color: #f9f9f9;
  }

  .clickable {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }

  .filesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6.25rem, 1fr));
    gap: 0.625rem;
    margin-top: 0.9375rem;
  }

  .fileItem {
    position: relative;
    border: 0.0625rem solid #ccc;
    border-radius: 0.25rem;
    padding: 0.3125rem;
    text-align: center;
  }

  .fileItem img {
    max-width: 100%;
    max-height: 15rem;
    object-fit: contain;
    display: block;
    margin: 0 auto;
  }

  .nonImage {
    font-size: 0.9rem;
    padding: 0.625rem;
  }

  .fileItem button {
    position: absolute;
    top: 0.3125rem;
    right: 0.3125rem;
    border: none;
    background: transparent;
    font-size: 1.2rem;
    cursor: pointer;
  }
}
</style>
