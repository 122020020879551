<template>
  <div :ref="id" class="chatMessage" :style="messageStyles">
    <div class="avatarWrapper" v-if="!isUserMessage">
      <div class="avatarContainer">
        <UserAvatar :id="role" :avatar-theme="chatAvatarStyles[activeUserChat]" :bot-config="botConfig" :url="url" />
        <span>{{ botConfig?.botName ? botConfig?.botName : 'Seezar' }}</span>
      </div>
      <button class="closeButton" v-if="isPopupMessage" @click="$emit('close')">
        <XIcon />
      </button>
    </div>
    <ChatLoading v-if="loading" :message="message" :chat-message-styes="chatMessageStyes" />
    <div v-else :class="{ userMessage: isUserMessage, fullWidth: !isUserMessage }">
      <div v-if="words" :class="{ message: true, error }">
        <span v-for="(word, index) in words" :key="index" v-html="word" />
      </div>
      <div v-else :class="{ message: true, error }">
        <div v-html="message" />
        <slot v-if="showFaqs" name="faqs" />
      </div>
    </div>
    <div v-if="!isPopupMessage" class="messageFooter">
      <time class="date">{{ dateStampFormatted }}</time>
      <button @click="selectFeedback(feedbackTypes.positive)" v-if="showFeedbackButtons">
        <ThumbsUpNotSelected v-if="localFeedback != feedbackTypes.positive" />
        <ThumbsUpSelected v-else />
      </button>
      <button @click="selectFeedback(feedbackTypes.negative)" v-if="showFeedbackButtons">
        <ThumbsDownNotSelected v-if="localFeedback != feedbackTypes.negative" />
        <ThumbsDownSelected v-else />
      </button>
    </div>
    <div class="feedbackResponseOptions" v-if="showFeedbackResponseOptions">
      <div class="header">
        <span class="heading"
          >{{ t.feedbackResponseHeading }}<span class="optional"> ({{ t.optional }})</span></span
        >
        <button @click="showFeedbackResponseOptions = false">
          <XIcon />
        </button>
      </div>
      <div class="options">
        <button class="option" @click="selectFeedbackResponseOption(option)" :class="{ selected: localFeedbackTag == option }" v-for="option in negativeFeedbackOptions" :key="option">{{ t[option] }}</button>
      </div>
      <div class="feedbackInputWrapper" v-if="localFeedbackTag == 'other'">
        <textarea :placeholder="t.feedbackInputPlaceholder" v-model="localFeedbackComments" />
        <button :disabled="disableSubmitButton" @click="submitFeedbackComments" class="feedbackSubmitButton">{{ t.submit }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import UserAvatar from './UserAvatar.ce.vue'
import ChatLoading from './ChatLoading.ce.vue'
import { langMixin } from '../lang'
import { hexToRgba, isColorWhite } from './helpers'
import XIcon from '@/assets/close.svg'
import ThumbsUpNotSelected from '@/assets/thumbs-up.svg'
import ThumbsDownNotSelected from '@/assets/thumbs-down.svg'
import ThumbsUpSelected from '@/assets/thumbs-up-filled.svg'
import ThumbsDownSelected from '@/assets/thumbs-down-filled.svg'
import { BorderRadius, chatfeedbackTypes } from './constants.js'
import { readOnlyModeMixin } from '../readOnlymode.js'

export default {
  name: 'ChatMessage',
  components: { UserAvatar, ChatLoading, XIcon, ThumbsUpNotSelected, ThumbsDownNotSelected, ThumbsUpSelected, ThumbsDownSelected },
  mixins: [langMixin('CHAT_COMPONENT_TRANSLATIONS'), readOnlyModeMixin],
  props: {
    message: { type: String, default: '' },
    role: { type: String, required: true },
    id: { type: String, required: true },
    date: { type: [String, Date], default: '' },
    chatAvatarStyles: { type: Object, required: true },
    chatMessageStyes: { type: Object, required: true },
    botConfig: { type: Object, default: () => {} },
    words: { type: Array, default: null },
    showFaqs: { type: Boolean, default: false },
    isPopupMessage: { type: Boolean, default: false },
    messageIndex: { type: Number, default: 0 },
    feedback: { type: String, default: '' },
    feedbackTag: { type: String, default: '' },
    feedbackComments: { type: String, default: '' }
  },
  emits: ['close', 'sendChatFeedback'],
  data() {
    return {
      htmlTypedMessage: '',
      typingSpeed: 8,
      typedMessage: '',
      typingIndex: 0,
      activeUserChat: 'userAvatarStyles',
      localFeedback: this.feedback,
      feedbackTypes: chatfeedbackTypes,
      showFeedbackResponseOptions: false,
      negativeFeedbackOptions: ['inaccurate', 'unclear', 'slow', 'outdated', 'other'],
      localFeedbackTag: this.feedbackTag,
      localFeedbackComments: this.feedbackComments
    }
  },
  computed: {
    loading() {
      return this.id === 'loading'
    },
    error() {
      return this.id === 'error'
    },
    url() {
      return this.activeUserChat === 'userAvatarStyles' ? this.botConfig.userAvatarImage : this.botConfig.botAvatarImage || ''
    },
    messageStyles() {
      const userColor = !this.botConfig.primaryColor || this.botConfig.primaryColor == 'Transparent' ? hexToRgba(this.chatMessageStyes.userMessageBgColor, 0.1) : hexToRgba(this.botConfig.primaryColor || '', 0.1)
      return {
        '--date-color': this.chatMessageStyes.dateColor,
        '--message-color': this.chatMessageStyes.messageColor,
        '--button-text-color': this.chatMessageStyes.buttonTextColor,
        '--align-chat-message': this.isUserMessage ? 'flex-end' : 'flex-start',
        '--user-message-bg': userColor,
        '--align-chat-text': this.isUserMessage ? 'unset' : 'start',
        '--feedback-response-options-border-radius': this.botConfig?.borderRadius == BorderRadius.SQUARE ? '8px' : '1em',
        '--feedback-response-border-color': this.checkColor(this.botConfig?.primaryColor) ? hexToRgba(this.botConfig?.primaryColor, 0.2) : hexToRgba(this.chatMessageStyes.userMessageBgColor, 0.2),
        '--feedback-response-background-color': this.checkColor(this.botConfig?.primaryColor) ? hexToRgba(this.botConfig?.primaryColor, 0.1) : hexToRgba(this.chatMessageStyes.userMessageBgColor, 0.1)
      }
    },
    dateStampFormatted() {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      }

      const language = this.language

      const formatDate = date => {
        return new Intl.DateTimeFormat(language, options).format(date)
      }
      const parsedDate = new Date(this.date)
      const formattedDate = formatDate(parsedDate)
      return formattedDate
    },
    isUserMessage() {
      return this.role === 'user'
    },
    showFeedbackButtons() {
      return !this.readOnlyMode && this.messageIndex && !this.isUserMessage && !this.loading && !this.words?.length && !this.message?.startsWith('<seez-sdk-clear-chat')
    },
    disableSubmitButton() {
      return !this.localFeedbackComments?.trim()
    }
  },
  watch: {
    feedbackTag(v) {
      this.localFeedbackTag = v
    },
    feedbackComments(v) {
      this.localFeedbackComments = v
    }
  },
  mounted() {
    this.defineTheme()
  },
  methods: {
    defineTheme() {
      this.activeUserChat = this.role === 'user' ? 'userAvatarStyles' : 'ceaserAvatarStyles'
    },
    selectFeedback(feedback) {
      this.showFeedbackResponseOptions = false
      const variables = { label: null, comments: null }

      if (feedback == this.localFeedback) {
        this.localFeedback = null
        variables.rating = null
      } else {
        this.localFeedback = feedback
        variables.rating = feedback

        if (feedback == this.feedbackTypes.negative) {
          this.showFeedbackResponseOptions = true
        }
      }

      this.$emit('sendChatFeedback', this.messageIndex, variables, false)
    },
    checkColor(color) {
      if (!color || color == 'Transparent' || isColorWhite(color)) return false
      return true
    },
    selectFeedbackResponseOption(option) {
      this.localFeedbackTag = option
      const variables = { comments: null, rating: this.feedbackTypes.negative }

      if (option != 'other') {
        variables.label = option
        this.$emit('sendChatFeedback', this.messageIndex, variables)
        this.hideFeedbackResponseOptions()
      }
    },
    submitFeedbackComments() {
      const variables = { comments: this.localFeedbackComments, rating: this.feedbackTypes.negative, label: 'other' }
      this.$emit('sendChatFeedback', this.messageIndex, variables)
      this.hideFeedbackResponseOptions()
    },
    hideFeedbackResponseOptions() {
      setTimeout(() => (this.showFeedbackResponseOptions = false), 1000)
    }
  }
}
</script>
<style>
.avatarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-self: var(--align-chat-message);

  closeButton {
    background: #f5f5f5;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  display: flex;
  align-items: center;
}

.avatarContainer {
  display: flex;
  gap: 0.5em;
  align-items: center;
  align-self: var(--align-chat-message);

  > span {
    font-size: 14px;
    color: black;
    font-weight: 600;
    font-family: var(--font-family);
  }
}

.date,
.message {
  padding: 0;
  margin: 0;
  overflow: auto;

  a {
    color: var(--message-color);
    font-weight: 600;
  }
}

.chatMessage {
  display: flex;
  flex-direction: column;
  align-items: var(--align-chat-message);
  gap: 0.5em;
  text-align: var(--align-chat-text);
}

.userMessage {
  background-color: var(--user-message-bg);
  padding: 8px;
  border-radius: 10px;
}

.fullWidth {
  width: 100%;
}

.date {
  color: var(--date-color);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.message {
  color: var(--message-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  white-space: pre-wrap;
  font-family: var(--font-family);
}

.message.error {
  color: red;
}

.messageFooter {
  display: flex;
  align-items: center;
}

.feedbackResponseOptions {
  border: 1px solid #e5e5e5;
  width: 100%;
  padding: 0.5em 0.8em;
  font-size: 12px;
  width: -webkit-fill-available;
  font-weight: 700;
  border-radius: var(--feedback-response-options-border-radius);

  display: flex;
  flex-direction: column;
  gap: 1em;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;

    .heading {
      font-weight: 700;
      color: black;

      .optional {
        color: #757575;
      }
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;

    .option {
      border: 1px solid #e5e5e5;
      padding: 5px;
      border-radius: var(--feedback-response-options-border-radius);

      &.selected {
        font-weight: 600;
        border-color: var(--feedback-response-border-color);
        background: var(--feedback-response-background-color);
      }
    }
  }

  .feedbackInputWrapper {
    display: flex;
    gap: 0.5em;
    width: 100%;

    textarea {
      width: 100%;
      border: 1px solid #e5e5e5;
      border-radius: var(--feedback-response-options-border-radius);
      outline: var(--feedback-response-border-color);
      padding: 5px;
      min-height: 1.75em;
      font-family: Manrope;
    }

    .feedbackSubmitButton {
      color: #2d2d2d;

      &:disabled {
        color: #666666;
      }
    }
  }
}
</style>
