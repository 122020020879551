export const chatStates = {
  LOADING: 'loading',
  ERROR: 'error',
  CLEAR: 'clear'
}

export const chatRoles = {
  USER: 'user',
  SYSTEM: 'system'
}

export const iconThemes = {
  light: 'white',
  dark: 'black'
}

export const radiusOptions = {
  square: '2px',
  rounded: '5em'
}
export const chatSizeKeys = {
  full: 'full',
  original: 'original',
  small: 'small'
}

export const customAttributes = {
  chatStartTime: true,
  automatedChatStart: true,
  longFirstMessage: true
}

export const booleanCustomAttributes = {
  automatedChatStart: true
}

export const WidgetPositions = {
  MIDDLE_LEFT: 'middleLeft',
  MIDDLE_RIGHT: 'middleRight',
  TOP_LEFT: 'topLeft',
  TOP_RIGHT: 'topRight',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM_RIGHT: 'bottomRight'
}

export const WidgetMargins = {
  MARGIN_SMALL: 'marginSmall',
  MARGIN_MEDIUM: 'marginMedium',
  MARGIN_LARGE: 'marginLarge',
  MARGIN_NONE: 'marginNone'
}

export const BorderRadius = {
  SQUARE: 'square',
  ROUNDED: 'rounded'
}

export const WidgetAnimations = {
  EXPAND: 'expand',
  SHAKE_HORIZONTAL: 'shakeHorizontal',
  SHAKE_VERTICAL: 'shakeVertical',
  FLIP: 'flip',
  SWING: 'swing',
  BOUNCE: 'bounce',
  NONE: 'none'
}

export const storageKeys = {
  LAST_SESSION_ID: 'Last-Session-Id',
  CURRENT_SESSION_ID: 'Seez-Session-Id'
}

export const webSocketOperations = {
  HANDSHAKE: 'handShake',
  SEND_MESSAGE: 'sendMessage',
  PING: 'ping',
  PREPROCESSING: 'preProcessing',
  ERROR: 'message',
  CHAT_REF: 'chatRef',
  UUID: 'uuid',
  METADATA: 'metaData',
  BOT_RESPONSE_ID: 'botResponseId'
}

export const reservedWebSocketStrings = {
  END_OF_CHAT: '{"done":true}',
  CONNECTED: 'success',
  PONG: 'pong'
}

export const reservedWebSocketStringsKeys = {
  [reservedWebSocketStrings.END_OF_CHAT]: true,
  [reservedWebSocketStrings.CONNECTED]: true,
  [reservedWebSocketStrings.PONG]: true
}

export const chatfeedbackTypes = {
  positive: 'thumbs_up',
  negative: 'thumbs_down'
}
