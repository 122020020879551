<template>
  <Modal :closable="closable" @close="close" :position="position">
    <div class="testDriveModal">
      <div v-if="!onSuccess">
        <div class="headingText">
          <h2 class="testDriveHeading">{{ t.heading }}</h2>
          <span>{{ listingDetails }}</span>
          <span>
            <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.94 12.22C2.72 10.7 0 7.06 0 5 0 2.52 1.99.5 4.5.5 6.98.5 9 2.52 9 5 9 7.06 6.26 10.7 5.04 12.22 4.76 12.57 4.22 12.57 3.94 12.22ZM4.5 6.5C5.32 6.5 6 5.84 6 5 6 4.18 5.32 3.5 4.5 3.5 3.66 3.5 3 4.18 3 5 3 5.84 3.66 6.5 4.5 6.5Z" fill="black" />
            </svg>
            {{ details?.locatedAt?.plainAddress?.split(',')?.[1] }}
          </span>
        </div>
        <p class="testDriveNotice" v-if="details.wholesalePrice">{{ t.testDriveNotice ?? 't.testDrivceNotice' }}</p>
        <form class="testDriveForm" @submit.prevent="submitTestDrive">
          <p>{{ t.timeTestDrive }}</p>
          <label for="date">
            <span>{{ t.date }}</span>
            <input id="date" :class="`${testDriveData.date ? 'has-value' : ''}`" :placeholder="t.date" data-test-id="date_input" aria-label="date input" type="date" required v-model="testDriveData.date" />
          </label>
          <label class="multiselectLabel" for="time">
            <span>{{ t.time }}</span>
            <div class="multiselect">
              <multi-select :options="timeSlots" :multi="false" v-model="testDriveData.timeSlot" :required="true" :placeholder="t.time" data-test-id="timeSlot" />
            </div>
          </label>
          <p>{{ t.contactInformation }}</p>
          <label for="firstName">
            <span>{{ t.firstName }}</span>
            <input data-test-id="firstName" id="firstName" :placeholder="t.firstName" v-model="testDriveData.firstName" required type="text" />
          </label>
          <label for="middleName">
            <span>{{ t.middleName }}</span>
            <input data-test-id="middleName" id="middleName" :placeholder="t.middleName" v-model="testDriveData.middleName" type="text" />
          </label>
          <label for="lastName">
            <span>{{ t.lastName }}</span>
            <input data-test-id="lastName" id="lastName" :placeholder="t.lastName" v-model="testDriveData.lastName" required type="text" />
          </label>
          <label for="email">
            <span>{{ t.email }}</span>
            <input data-test-id="email" id="email" :placeholder="t.email" v-model="testDriveData.email" required type="email" />
          </label>
          <label for="phoneNumber">
            <span>{{ t.phone }}</span>
            <input data-test-id="phone" id="phoneNumber" :placeholder="t.phone" v-model="testDriveData.phoneNumber" required type="text" pattern="[\d\+\-\s\(\)]{7,}" />
          </label>
          <label for="postalCode">
            <span>{{ t.postalCode }}</span>
            <input data-test-id="postalCode" id="postalCode" :placeholder="t.postalCode" v-model="testDriveData.postalCode" required type="text" />
          </label>
          <div class="termsWrapper">
            <div class="checkboxWrapper">
              <input data-test-id="terms" id="terms" type="checkbox" v-model="testDriveData.marketingConsent" />
              <label for="terms">{{ t.terms }}</label>
              <label class="customTermsLabel" for="terms" />
            </div>
            <div class="checkboxWrapper">
              <input data-test-id="marketing" id="terms-policy" :class="checkboxError" type="checkbox" v-model="testDriveData.termsPolicy" />
              <label for="terms-policy">{{ t.termsPolicy }}</label>
              <label class="customPolicyLabel" for="terms-policy">
                <a v-if="policyUrl" class="policyLink" :href="policyUrl" target="_blank" rel="noreferrer" />
              </label>
            </div>
          </div>
          <p v-if="error" class="error">{{ error.message }}</p>
          <button class="tradeInBtn" id="testDriveBtn" type="submit">{{ t.submit }}</button>
        </form>
        <div class="info" v-if="contactPhone">
          <p>
            {{ t.forMoreInfo }} <a href="tel:+{{ contactPhone }}">{{ contactPhone }}</a
            >&nbsp; {{ t.referenceText }} <b>{{ details.referenceNumber }}</b>
          </p>
          <slot />
        </div>
      </div>
      <div v-if="onSuccess" class="success">
        <div class="iconWrapper">
          <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 23.75C0 11.08 10.24.75 23 .75 35.67.75 46 11.08 46 23.75 46 36.51 35.67 46.75 23 46.75 10.24 46.75 0 36.51 0 23.75ZM33.33 19.8C34.32 18.81 34.32 17.28 33.33 16.29 32.34 15.3 30.82 15.3 29.83 16.29L20.13 26 16.08 22.04C15.09 21.05 13.57 21.05 12.58 22.04 11.59 23.03 11.59 24.56 12.58 25.55L18.33 31.3C19.32 32.29 20.84 32.29 21.83 31.3L33.33 19.8Z" fill="#33AE5C" />
          </svg>
        </div>
        <h1>{{ t.thankYouTitle }}</h1>
        <p>{{ t.thankYouText }}</p>
        <button @click="$emit('close')">{{ t.backInfo }}</button>
      </div>
      <Loader v-if="loading" />
    </div>
  </Modal>
</template>

<script>
import Modal from '../Modal.ce.vue'
import Loader from '../Loader.ce.vue'
import MultiSelect from '../MultiSelect.ce.vue'
import SeezSdk from '../../sdk'
import { analyticsMixin } from '../../analytics.js'
import { langMixin } from '../lang'

export default {
  name: 'TestDriveModal',
  components: { Modal, Loader, MultiSelect },
  mixins: [langMixin('TestDriveModal'), SeezSdk.vueQueryMixin, analyticsMixin],
  props: {
    closable: { type: Boolean, default: true },
    position: { type: String, default: 'center' },
    timeOpen: { type: String, default: '09:00' },
    timeClose: { type: String, default: '16:00' },
    listingId: { type: String, default: null },
    policyUrl: { type: String, default: '' }
  },
  emits: ['close', 'sent', 'submit'],
  data() {
    return {
      onSuccess: false,
      loading: false,
      hasMarketingAccepted: false,
      error: {
        type: '',
        message: ''
      },
      details: {
        location: '',
        year: '',
        variant: '',
        locatedAt: {
          plainAddress: ''
        },
        referenceNumber: '_'
      },
      testDriveData: {
        date: '',
        timeSlot: null,
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        terms: false,
        marketingConsent: false
      }
    }
  },
  computed: {
    listingDetails() {
      return `${this.details.year} ${this.details.name} `
    },
    timeSlots() {
      const selectedDate = new Date(this.testDriveData.date)
      const isWeekend = selectedDate && (selectedDate.getDay() === 0 || selectedDate.getDay() === 6)

      const getSlotsFromIndex = (_, i) => {
        const regex = /\d(\d\d)/g
        const result = ('0' + ~~(i / 4) + ':0' + 60 * ((i / 4) % 1)).replace(regex, '$1')
        return { id: result, name: result }
      }

      const availableSlots = Array(24 * 4)
        .fill(0)
        .map(getSlotsFromIndex)

      const timeOpen = isWeekend ? '11:00' : this.timeOpen
      const timeClose = isWeekend ? '16:00' : this.timeClose

      const indexTimeOpen = availableSlots.findIndex(item => item.name === timeOpen)
      const openTimeArray = availableSlots.filter((_, index) => index >= indexTimeOpen)

      const indexTimeClose = openTimeArray.findIndex(item => item.name === timeClose)

      const filteredArray = openTimeArray.filter((_, index) => index <= indexTimeClose)

      if (filteredArray.length === 0) {
        throw new Error('timeClose must be grater than timeOpen or input was wrongly typed. Ex: timeOpen=09:00 timeClose=23:00')
      }

      return filteredArray
    },
    checkboxError() {
      return { error: this.error && this.error.type === 'terms' }
    },
    mainImageUrl() {
      return this.details.images ? this.imageUrl(this.details.images?.[0], 200) : ''
    },
    contactPhone() {
      return this.details?.targetSite?.customerSupport?.phoneNumber ?? null
    }
  },
  mounted() {
    this.loadDetails()
    this.getUserData()
  },
  methods: {
    imageUrl(path, size) {
      return `${import.meta.env.VITE_IMAGES_URL}/image/${size}x0/${path}.webp`
    },

    async loadDetails() {
      this.loading = true
      this.error = null
      try {
        const query = `{
          listing(id: ${this.listingId}) {
            id name year referenceNumber images wholesalePrice { value type }
            locatedAt { plainAddress } 
            targetSite {
              customerSupport { phoneNumber }
            }
          }
        }`

        const result = await this.queryApi(query)

        if (!result.listing) {
          this.error.message = { message: 'Error loading listing' }
          return (this.loading = false)
        }

        this.details = { ...result.listing }
        this.loading = false
      } catch (error) {
        console.error(error)
        this.error = { message: 'Error loading listing details' }
        this.loading = false
      }
    },
    async submitTestDrive() {
      this.error = null
      const allTermsSelected = this.testDriveData.termsPolicy // this.testDriveData.terms
      if (!allTermsSelected) {
        return (this.error = { type: 'terms', message: 'Du skal acceptere vilkår for brug og privatlivspolitik for at fortsætte' })
      }
      this.loading = true

      const when = new Date(`${this.testDriveData.date}T${this.testDriveData.timeSlot}:00.000Z`).toISOString()

      const variables = {
        payload: {
          listingId: this.details.id,
          when,
          firstName: this.testDriveData.firstName,
          middleName: this.testDriveData.middleName,
          lastName: this.testDriveData.lastName,
          email: this.testDriveData.email,
          phone: this.testDriveData.phoneNumber,
          postalCode: this.testDriveData.postalCode
        }
      }

      try {
        await this.queryApi('mutation createTestDrive($payload:TestDriveInput!){createTestDrive(input:$payload){id}}', variables)
        this.$emit('submit', this.testDriveData)
        this.track('asc_cta_interaction', { old_name: 'test_drive_sent', element_text: 'submit test drive', element_subtype: 'cta_button', element_type: 'button', testDrive: this.testDriveData })
        this.loading = false
        this.onSuccess = true
      } catch (error) {
        this.error = error
        this.loading = false
      }

      if (this.testDriveData.marketingConsent !== this.hasMarketingAccepted) {
        try {
          await this.queryApi(`mutation { changeMarketingPreferences(accepted:${this.testDriveData.marketingConsent}) }`)
        } catch (e) {
          console.log('Error updating marketing consent', e)
        }
      }
    },
    async getUserData() {
      const user = await window.seezSdk.getCurrentUser(null, false)
      if (user) {
        const { me } = await this.queryApi('{me{firstName,middleName,lastName,email,acceptedMarketingTerms,phone,address{postalCode}}}')

        this.testDriveData = {
          ...this.testDriveData,
          firstName: me.firstName,
          middleName: me.middleName,
          lastName: me.lastName,
          phoneNumber: me.phone,
          email: me.email,
          postalCode: me.address?.postalCode
        }

        this.hasMarketingAccepted = Boolean(me.acceptedMarketingTerms)
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.testDriveModal {
  div > .headingText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid black;
    margin-block-end: 1rem;
    padding-block-end: 2rem;

    @media screen and (min-width: 40rem) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-block-end: 0;
    }

    > div > span {
      font-size: 0.9rem;

      @media screen and (min-width: 40rem) {
        margin: 0 0.5rem;
      }
    }

    > div > :nth-child(2) {
      background-color: #f5f5f5;
      padding: 0.3rem 1.2rem;
      border-radius: 15px;
      font-weight: 600;
      font-size: 12.8px;
      margin: 1rem;
      white-space: nowrap;

      > svg {
        padding-inline-end: 0.3rem;
      }
    }
  }

  div > .testDriveForm {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    text-align: start;
    gap: 0.5rem;

    & > * {
      grid-column: span 6 / span 6;
    }

    @media screen and (min-width: 40rem) {
      :nth-child(2),
      :nth-child(3),
      :nth-child(9),
      :nth-child(10) {
        grid-column: span 3 / span 3;
      }

      :nth-child(5),
      :nth-child(6),
      :nth-child(7) {
        grid-column: span 2 / span 2;
      }
    }

    > .multiselectLabel {
      .multiselect {
        position: relative;

        > button {
          width: 100%;
          box-sizing: initial;
          padding: 0.2em 0.1em;
          margin: 0;
          background: white;
          border: 1px solid black;
          border-radius: 0;
          font-family: sans-serif;
          color: black;

          > svg {
            position: absolute;
            inset-inline-end: 1em;
            inset-block-start: 0.6em;
            transition: transform 50ms;
          }
        }
      }
    }

    > label {
      margin: 0.2rem 0;
      display: flex;
      flex-direction: column;

      input[type='date']:not(.has-value):before {
        color: black;
        white-space: nowrap;
        content: attr(placeholder);
        display: block;
        height: 0;
      }

      input[type='date']:required:invalid::-webkit-datetime-edit {
        color: transparent;
      }

      input[type='date']:focus::-webkit-datetime-edit {
        color: black !important;
        padding-inline-start: 0.5rem;
      }

      input[type='date']:focus:before {
        content: '';
      }
    }

    > .tradeInBtn {
      cursor: pointer;
      width: inherit;
      margin: 1rem 0 0 0;
      width: 100%;
    }

    > .error {
      color: red;
      align-self: center;
      text-align: center;
      margin: 0;
    }

    > .termsWrapper {
      font-size: 10.4px;
      display: flex;
      flex-direction: column;
      text-align: start;

      > label {
        padding-inline-start: 0.2rem;
      }

      .error {
        outline: 1px solid red;
        border: none;
      }
    }
  }

  > .loader {
    position: absolute;
    inset: 0;
  }

  div > .info {
    max-width: 531px;
    text-align: start;
    border-top: 1px solid black;
    margin: 2rem 0 0 0;

    > p > a {
      color: black;
      font-family: inherit;
    }
  }

  > .success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .iconWrapper {
      padding: 0.5rem;
      background-color: #b3e1c2;
      border-radius: 999px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
    }

    > p {
      max-width: 511px;
      text-align: center;
    }

    > button {
      margin: 1rem 0;
      cursor: pointer;
    }
  }
}
</style>
