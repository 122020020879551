<template>
  <div v-if="selectedListingDetails" class="listingSelector">
    <article data-test-id="listingSelectorExpand" @click="selectorExpanded = !selectorExpanded" :class="{ expanded: selectorExpanded, expansible: listingsDetails.length > 1 }">
      <ChevronIcon class="chevronIcon" />
      <img :src="listingImageUrl(selectedListingDetails)" :alt="selectedListingDetails.name" />
      <span>{{ selectedListingDetails.year }} {{ selectedListingDetails.name }}</span>
      <span class="address">
        <LocationIcon class="locationIcon" />
        {{ selectedListingDetails?.vehicle?.locatedAt?.plainAddress ?? '-' }}</span
      >
    </article>
    <ul v-if="selectorExpanded && listingsDetails.length > 1">
      <li v-for="l in listingsDetails" :data-test-id="`listingItem${l.id}`" :key="l.id" @click="() => listingSelected(l)">
        <article :class="{ selected: l.id === selectedListingDetails?.id }">
          <img :src="listingImageUrl(l)" :alt="l.name" />
          <span>{{ l.year }} {{ l.name }}</span>
          <span>{{ l.locatedAt.plainAddress }}</span>
          <input type="radio" :checked="l.id === selectedListingDetails?.id" />
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
import LocationIcon from '../../assets/location.svg'
import ChevronIcon from '../../assets/chevron-down.svg'
export default {
  name: 'ListingSelector',
  components: { LocationIcon, ChevronIcon },
  props: {
    listingsDetails: {
      type: Array,
      default: () => []
    },
    selectedListingDetails: {
      type: Object,
      default: null
    }
  },
  emits: ['listing-selected'],
  data() {
    return {
      selectorExpanded: false
    }
  },
  methods: {
    listingImageUrl(listing) {
      if (listing?.images?.[0] == null) return `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
      return `${import.meta.env.VITE_IMAGES_URL}/image/0x60/${listing.images[0]}.webp`
    },
    listingSelected(listing) {
      this.selectorExpanded = false
      this.$emit('listing-selected', listing)
    }
  }
}
</script>

<style>
.listingSelector {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'selected' 'others';
  padding: 0.5rem 0.2rem;
}

.listingSelector > article {
  grid-area: selected;
  position: relative;
  width: 100%;
  color: var(--message-color, black);

  .address {
    white-space: nowrap;
    overflow: auto;
    width: 90%;
  }
}

.listingSelector > article.expansible::after {
  content: '';
  position: absolute;
  inset-inline-end: 1rem;
  inset-block-start: calc(50% - 1.25rem);
  font-size: 2rem;
}

.listingSelector > article.expansible.expanded::after {
  content: '';
}

.listingSelector > ul {
  grid-area: others;
  position: absolute;
  background-color: var(--background);
}

.listingSelector > ul > li {
  cursor: pointer;
}

.listingSelector > ul > li:not(:last-child) {
  margin-block-end: 0.5rem;
}

.listingSelector > article {
  display: grid;
  gap: 0.25rem 1rem;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'image title' 'image subtitle';
}

.listingSelector > article > img {
  grid-area: image;
  height: 4rem;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.listingSelector > article > span:first-of-type {
  grid-area: title;
  align-self: flex-end;
}

.listingSelector > article > .chevronIcon {
  display: none;
}

.listingSelector > .expansible {
  .chevronIcon {
    position: absolute;
    inset-inline-end: 0rem;
    inset-block-start: calc(50% - 1.25rem);
    font-size: 1.5rem;
    cursor: pointer;
    display: block;

    @media screen and (min-width: 50rem) {
      inset-inline-end: 0.2rem;
    }

    path {
      fill: var(--icon-path-fill, black);
    }

    rect {
      fill: var(--icon-rect-fill, transparent);
    }
  }
}

.listingSelector > .expanded {
  .chevronIcon {
    display: block;
    transform: rotate(180deg);
  }
}

.listingSelector > article > span:last-of-type {
  grid-area: subtitle;
  align-items: flex-start;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  gap: 0.3rem;

  .locationIcon {
    min-width: 0.8rem;
    width: 0.8rem;
    path {
      fill: var(--primary-color, var(--hightlight));
    }
  }
}

.listingSelector > ul > li > article {
  position: relative;
}

.listingSelector > ul > li > article > input {
  background-color: red;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>
