export const analyticsMixin = {
  // emits: ['track'],
  props: {
    analyticsData: { type: String, default: '' }
  },
  methods: {
    track(eventKey, properties, vData) {
      const atp = this.getAdditionalTrackingProps()
      if (this.readOnlyMode) return // Do not track if in read-only mode
      return window.seezSdk.track(eventKey, { ...atp, ...properties }, vData)
    },
    identify({ userId, name, email, marketing, loginStatus }) {
      if (this.readOnlyMode) {
        // Do not track if in read-only mode
        return
      }
      return window.seezSdk.identify({ userId, name, email, marketing, loginStatus })
    },
    getAdditionalTrackingProps() {
      const result = {
        ...this.$parent?.getAdditionalTrackingProps?.(),
        ...Object.fromEntries(new URLSearchParams(this.analyticsData ?? ''))
      }
      for (const td of this.$options.trackData ?? []) {
        result[td] = typeof this[td] === 'function' ? this[td]() : this[td]
      }
      return result
    }
  }
}

export const analyticsEvents = {
  CTA_CLICK: 'cta_click',
  TRADE_IN_REQUEST_SUBMITTED: 'trade_in_request_submitted',
  TRADE_IN_REG_INPUT: 'trade_in_reg_input ',
  FAVOURITE_CAR: 'favourite_car'
}
