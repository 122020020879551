<template>
  <div>
    <div class="testDriveFormWrapper" v-if="isOpeningHours">
      <div v-if="!onSuccess">
        <div class="headingText">
          <h2 class="testDriveHeading">{{ t.heading }}</h2>
        </div>
        <div class="detail-showroom" v-if="!loading && listingId">
          <div class="image">
            <img :src="mainImageUrl" :alt="details.name" />
          </div>
          <div class="infotext">
            <span>{{ listingDetails }}</span>
            <span>
              <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.94 12.22C2.72 10.7 0 7.06 0 5 0 2.52 1.99.5 4.5.5 6.98.5 9 2.52 9 5 9 7.06 6.26 10.7 5.04 12.22 4.76 12.57 4.22 12.57 3.94 12.22ZM4.5 6.5C5.32 6.5 6 5.84 6 5 6 4.18 5.32 3.5 4.5 3.5 3.66 3.5 3 4.18 3 5 3 5.84 3.66 6.5 4.5 6.5Z" fill="black" />
              </svg>
              <span v-if="details?.internalNote">{{ details?.internalNote }}</span>
              <span v-else>{{ details?.locatedAt?.plainAddress?.split(',')?.[1] ? details?.locatedAt?.plainAddress?.split(',')?.[1] : details?.locatedAt?.plainAddress?.split(',')?.[0] }}</span>
            </span>
          </div>
        </div>
        <div class="locationAndReservation" v-if="showLocationAndReservation">
          <p id="testLocation">{{ t.locationTestDrive }}</p>
          <div v-for="l in locationTypes" :key="l.value" :class="locationClass(l.value)" @click="chooseLocationType(l.value)">
            <div class="selectionInfo">
              <span>{{ l.title }}</span>
              <span v-if="l.subTitle">{{ l.subTitle }}</span>
            </div>
            <div class="radio"><div /></div>
          </div>
          <p id="wouldReserve" v-if="locationType === 'onsite'">{{ t.wouldReserve }}</p>
          <div v-for="r in reserveTypes" :key="r.value" :class="reserveClass(r.value)" @click="chooseReserve(r.value)">
            <div class="selectionInfo row">
              <span>{{ r.title }}</span>
              <span>{{ r.subTitle }}</span>
            </div>
            <div class="radio"><div /></div>
          </div>
          <div class="dateAndTimeText">
            <InfoCircle />
            <span>{{ t.reservationCaution }}</span>
          </div>
          <label class="multiselectLabel" for="location" v-if="locationType === 'preferred'">
            <p id="preferredLocation">{{ t.preferredLocation }}</p>
            <div class="multiselect">
              <multi-select :options="locations" :multi="false" v-model="testDriveData.location" :required="true" :placeholder="'Location'" data-test-id="timeSlot" @update:model-value="handleChangeValue($event)" />
              <p v-if="errorLocation" class="fieldError"><InfoCircle /> {{ t.errorLocation }}</p>
            </div>
          </label>
        </div>
        <form ref="testDriveForm" class="testDriveForm" @submit.prevent="">
          <p>{{ t.timeTestDrive }}</p>
          <label for="date">
            <span>{{ t.date }}</span>
            <input :min="minAllowed" id="date" ref="selectedDateValue" @change="validateDate" :class="`${testDriveData.date ? 'has-value' : ''} ${errorDateMessage && 'errorBorder'}`" :placeholder="t.date" onclick="this.showPicker()" max="9999-12-31" data-test-id="date_input" aria-label="date input" type="date" required v-model="testDriveData.date" />
          </label>
          <label class="multiselectLabel" for="time">
            <span>{{ t.time }}</span>
            <div class="multiselect">
              <multi-select :options="timeSlots" :multi="false" v-model="testDriveData.timeSlot" :required="true" :placeholder="t.time" data-test-id="timeSlot" />
            </div>
            <p v-if="errorTime" class="fieldError"><InfoCircle /> {{ t.errorTime }}</p>
          </label>
          <div :class="['dateAndTimeText', errorDateMessage && 'errorDate']">
            <InfoCircle />
            <span>{{ errorDateMessage || t.dateAndTimeText }}</span>
          </div>
          <p>{{ t.contactInformation }}</p>
          <label for="firstName">
            <span>{{ t.firstName }}</span>
            <input data-test-id="firstName" id="firstName" :placeholder="`${t.firstName} *`" v-model="testDriveData.firstName" required type="text" />
          </label>
          <label for="middleName">
            <span>{{ t.middleName }}</span>
            <input data-test-id="middleName" id="middleName" :placeholder="t.middleName" v-model="testDriveData.middleName" type="text" />
          </label>
          <label for="lastName">
            <span>{{ t.lastName }}</span>
            <input data-test-id="lastName" id="lastName" :placeholder="`${t.lastName} *`" v-model="testDriveData.lastName" required type="text" />
          </label>
          <label for="email">
            <span>{{ t.email }}</span>
            <input data-test-id="email" id="email" :placeholder="`${t.email} *`" v-model="testDriveData.email" required type="email" />
            <p v-if="emailError" class="fieldError">{{ t.testDriveEmailError ?? 't.testDriveEmailError' }}</p>
          </label>
          <label for="phoneNumber">
            <span>{{ t.phone }}</span>
            <input data-test-id="phone" id="phoneNumber" :placeholder="`${t.phone} *`" v-model="testDriveData.phoneNumber" required type="text" pattern="[\d\+\-\s\(\)]{7,}" />
          </label>
          <!-- <label for="nationalID" v-if="source === sourceBarchetti && locationType === 'onsite' && reserveType === 'yes' || locationType === 'preferred'">
            <span>{{ t.nationalID }}</span>
            <input
id="nationalID" :placeholder="`${t.nationalID} *`" v-model="testDriveData.nationalID" required type="text" />
          </label> -->
          <label for="postalCode">
            <span>{{ t.postalCode }}</span>
            <input data-test-id="postalCode" id="postalCode" :placeholder="t.postalCode" v-model="testDriveData.postalCode" type="text" />
          </label>
          <label for="qid">
            <span>{{ t.qid }}</span>
            <input data-test-id="qid" id="qid" :placeholder="`${t.qid} *`" :class="[errorQID ? 'inputError' : '']" v-model="testDriveData.qid" type="text" :required="source === sourceAam" maxlength="11" />
            <p v-if="errorQID" class="fieldError"><InfoCircle /> {{ t.errorQid }}</p>
          </label>
          <label class="multiselectLabel" for="region" v-if="source === sourceAam">
            <span>{{ t.region }}</span>
            <div class="multiselect">
              <multi-select :options="regions" :multi="false" v-model="testDriveData.region" :required="true" :placeholder="t.region" data-test-id="region" :other-region="otherRegion" />
            </div>
            <p v-if="errorRegion" class="fieldError"><InfoCircle /> {{ t.errorRegion ?? 't.errorRegion' }}</p>
          </label>
          <div class="qidText" v-if="source === sourceAam">
            <InfoCircle />
            <span>{{ t.qidText }}</span>
          </div>
          <label class="check">
            <input data-test-id="terms" name="policy" v-model="testDriveData.termsPolicy" type="checkbox" required />
            <slot name="policy">
              <span v-html="policyHtml" />
            </slot>
          </label>
          <label class="check">
            <input data-test-id="marketing" name="marketing" v-model="testDriveData.marketingConsent" type="checkbox" />
            <slot name="marketing">
              <span>{{ t.marketing }}</span>
            </slot>
          </label>
          <p v-if="error" class="error">{{ error.message }}</p>
          <p v-if="errorSubmitTestDrive" class="submitError">{{ t[errorSubmitTestDrive] ?? 't.errorSubmitTestDrive' }}</p>
          <button @click="submitTestDrive" v-if="source !== sourceBarchetti" class="tradeInBtn" id="testDriveBtn" type="submit">{{ t.submit }}</button>
          <div v-else>
            <div v-if="(locationType === 'onsite' && reserveType === 'yes') || locationType === 'preferred'" class="paymentOptions">
              <button @click="() => submitTestDrive('paypal')" type="submit" class="paypal">
                <Paypal />
              </button>
              <button @click="() => submitTestDrive('card')" type="submit" class="card">
                <CreditCard />
                <span>Credit card</span>
                <div style="width: 16px" />
              </button>
            </div>
            <button @click="submitTestDrive" style="width: 100%" v-else class="tradeInBtn" id="testDriveBtn" type="submit">{{ t.submit }}</button>
          </div>
        </form>
        <div class="info" v-if="!loading && listingId && contactPhone">
          <p v-if="source !== sourceBarchetti">
            {{ t.forMoreInfo }} <a href="tel:+{{ contactPhone }}">{{ contactPhone }}</a
            >&nbsp; {{ t.referenceText }} <b>{{ details.referenceNumber }}</b>
          </p>
          <p v-else>{{ t.forMoreInfoEmail }} <a href="mailto:bdc@barchetti.it">bdc@barchetti.it</a></p>
          <slot />
        </div>
      </div>
      <div v-if="onSuccess" class="success">
        <div class="iconWrapper">
          <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 23.75C0 11.08 10.24.75 23 .75 35.67.75 46 11.08 46 23.75 46 36.51 35.67 46.75 23 46.75 10.24 46.75 0 36.51 0 23.75ZM33.33 19.8C34.32 18.81 34.32 17.28 33.33 16.29 32.34 15.3 30.82 15.3 29.83 16.29L20.13 26 16.08 22.04C15.09 21.05 13.57 21.05 12.58 22.04 11.59 23.03 11.59 24.56 12.58 25.55L18.33 31.3C19.32 32.29 20.84 32.29 21.83 31.3L33.33 19.8Z" fill="#33AE5C" />
          </svg>
        </div>
        <h1>{{ t.thankYouTitle }}</h1>
        <p>{{ t.thankYouText }}</p>
      </div>
      <div class="loader-container" v-if="loading">
        <Loader />
        <span v-if="redirectingToPaymentProvider">Redirecting you the payment provider...</span>
      </div>
    </div>
    <div v-if="!isOpeningHours">
      <p class="no-opening">We don't have slot test drive for this vehicle</p>
    </div>
  </div>
</template>

<script>
import Loader from '../Loader.ce.vue'
import MultiSelect from '../MultiSelect.ce.vue'
import SeezSdk from '../../sdk'
import { analyticsMixin } from '../../analytics.js'
import { langMixin } from '../lang'
import InfoCircle from '../../assets/info-circle.svg'
import { validateQid } from '../../logic.js'
import CreditCard from '../../assets/credit-card.svg'
import Paypal from '../../assets/paypal.svg'

const sourceAam = 'aam'
const sourceBarchetti = 'barchetti'
const timeInSecond = 1000
const secondsInMinute = 60
const minutesInHour = 60
const hoursInDay = 24
const timeInDay = secondsInMinute * minutesInHour * hoursInDay * timeInSecond
const defaultTimeSlot = 15
const formattedPrefix = '0'

export default {
  name: 'TestDriveForm',
  components: { Loader, MultiSelect, InfoCircle, Paypal, CreditCard },
  mixins: [langMixin('TestDriveModal'), SeezSdk.vueQueryMixin, analyticsMixin],
  props: {
    timeOpen: { type: String, default: '09:00' },
    timeClose: { type: String, default: '16:00' },
    listingId: { type: String, default: null },
    source: { type: String, default: '' },
    redirectDomain: { type: String, default: '' }
  },
  emits: ['sent', 'submit'],
  data() {
    return {
      errorDateMessage: false,
      onSuccess: false,
      isOpeningHours: true,
      errorSubmitTestDrive: null,
      preferredLocation: {
        value: 0,
        currency: ''
      },
      loading: false,
      redirectingToPaymentProvider: false,
      sourceAam,
      sourceBarchetti,
      errorTime: false,
      errorLocation: false,
      errorRegion: false,
      emailError: '',
      error: {
        type: '',
        message: ''
      },
      details: {
        location: '',
        year: '',
        variant: '',
        locatedAt: {
          plainAddress: '',
          openingHours: {
            week: [],
            testDriveTimeSlotSize: null,
            holidays: []
          }
        },
        referenceNumber: '_'
      },
      targetSiteConfig: null,
      testDriveData: {
        nationalID: '',
        date: '',
        timeSlot: null,
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        qid: '',
        region: null,
        terms: false,
        termsPolicy: false,
        marketingConsent: false
      },
      locationType: null,
      reserveType: null,
      hasMarketingAccepted: false,
      paymentMethods: {
        paypal: 'paypalAuthCaptureRefund',
        card: 'cardAuthCaptureRefund'
      }
    }
  },
  computed: {
    listingDetails() {
      return `${this.details.year} ${this.details.name} `
    },
    regions() {
      return window.getRegionOnAAM()?.map(region => {
        return {
          id: region,
          name: region
        }
      })
    },
    otherRegion() {
      return this.source === this.sourceAam ? (this.t.otherRegion ?? 't.otherRegion') : ''
    },
    timeSlots() {
      if (this.testDriveData.date) {
        const selectedDate = new Date(this.testDriveData.date)
        const currentTimeDate = this.details.locatedAt?.openingHours?.week[selectedDate.getDay()] || null
        const testDriveTimeSlotSize = this.details.locatedAt?.openingHours?.testDriveTimeSlotSize || defaultTimeSlot
        const getSlotsFromIndex = (_, i) => {
          const hours = Math.floor((i * testDriveTimeSlotSize) / secondsInMinute)
          const minutes = (i * testDriveTimeSlotSize) % secondsInMinute
          const formattedHours = (formattedPrefix + hours).slice(-2)
          const formattedMinutes = (formattedPrefix + minutes).slice(-2)
          const result = `${formattedHours}:${formattedMinutes}`
          return { id: result, name: result }
        }

        const numberOfSlots = (hoursInDay * minutesInHour) / testDriveTimeSlotSize

        const availableSlots = Array(numberOfSlots).fill(0).map(getSlotsFromIndex)
        const timeOpen = currentTimeDate ? currentTimeDate.from : this.timeOpen
        const timeClose = currentTimeDate ? currentTimeDate.to : this.timeClose

        const filteredArray = availableSlots.filter(item => {
          //hard code for aam
          if (this.source === sourceAam) {
            const logicForAam = (item.name >= '09:00' && item.name <= '12:00') || (item.name >= '16:00' && item.name <= '19:00')
            return logicForAam
          }

          return item.name >= timeOpen && item.name <= timeClose
        })

        return filteredArray
      }
      return []
    },
    checkboxError() {
      return { error: this.error && this.error.type === 'terms' }
    },
    policyHtml() {
      return this.t.policy?.replace('{terms}', `<a href="${this.targetSiteConfig?.urlConfig?.termsAndConditionsUrl}" target="_blank" rel="noreferrer">${this.t.terms}</a>`).replace('{policy}', `<a href="${this.targetSiteConfig?.urlConfig?.privacyPolicyUrl}" target="_blank" rel="noreferrer">${this.t.privacy}</a>`)
    },
    mainImageUrl() {
      return this.details.images ? this.imageUrl(this.details.images?.[0], 200) : ''
    },
    errorQID() {
      return validateQid(this.source, this.testDriveData?.qid)
    },
    contactPhone() {
      return this.targetSiteConfig?.customerSupport?.phoneNumber ?? null
    },
    locationTypes() {
      return [
        { value: 'onsite', title: this.t.onsiteTestDrive, subTitle: this.t.free },
        { value: 'preferred', title: this.t.selectPreferredLocation, subTitle: `${this.t.price}: ${this.formatPrice(this.preferredLocation.value ? this.preferredLocation : { value: 300, currency: 'EUR' }, this.preferredLocation?.currency ?? 'EUR')}` }
      ]
    },
    reserveTypes() {
      if (this.locationType === 'onsite')
        return [
          { value: 'yes', title: this.t.yes, subTitle: `${this.t.price}: ${this.details?.locatedAt?.testDriveRelocationPrice ? this.formatPrice(this.details?.locatedAt?.testDriveRelocationPrice, this.details?.locatedAt?.testDriveRelocationPrice?.currency) : ''}` },
          { value: 'no', title: this.t.no, subTitle: this.t.free }
        ]
      return []
    },
    locations() {
      return this.details?.owner?.descendants || []
    },
    showLocationAndReservation() {
      return this.source === sourceBarchetti && this.details?.id
    },
    minAllowed() {
      let today = new Date()
      if (this.source === this.sourceBarchetti) {
        today.setDate(today.getDate() + 7) // Adding 7 days to the current date
      }

      return today.toISOString().split('T')[0] // Formatting to "YYYY-MM-DD"
    }
  },
  watch: {
    'testDriveData.timeSlot': 'removeRequiredError'
  },
  mounted() {
    this.loadDetails()
    this.getUserData()
  },
  methods: {
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailPattern.test(this.testDriveData.email)) {
        this.emailError = 'testDriveEmailError'
        return false
      }
      this.emailError = ''
      return true
    },
    removeRequiredError() {
      this.errorTime = false
    },
    validateDate() {
      this.testDriveData.timeSlot = null
      const holidays = this.details.locatedAt?.openingHours?.holidays || []
      const selectedDate = new Date(this.testDriveData.date)
      const dateInWeek = this.details.locatedAt?.openingHours?.week[selectedDate.getDay()]
      const isDateInHolidays = this.isDateInRange(this.testDriveData.date, holidays[0], holidays[1])
      const currentDay = new Date()
      const isDayInPastAndToday = new Date(this.testDriveData.date).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)

      //only for aam - hard code
      const isFriday = this.source === sourceAam && selectedDate.getDay() === 5
      const next3Days = new Date(currentDay.getTime() + 3 * timeInDay)
      const isOutNext3Days = this.source === sourceAam && selectedDate.getTime() > next3Days.getTime()
      //only for aam - hard code
      const isEmptyInWeek = dateInWeek === null && this.source !== sourceAam

      if (isDateInHolidays || isEmptyInWeek || isDayInPastAndToday || isFriday || isOutNext3Days) {
        let message = ''
        if (isDayInPastAndToday) {
          message = this.t.pleaseSelectNext3Days
        } else if (isOutNext3Days) {
          message = this.t.outNext3Days
        } else {
          message = this.t.showroomClosedThisDate
        }

        this.errorDateMessage = message
        this.testDriveData.date = null
      } else {
        this.errorDateMessage = null
      }
    },
    renderPayload(locationType) {
      if (locationType === 'onsite') {
        return {
          locationId: this.details?.locatedAt?.id
          // reserve : reserve === 'yes' ? true : false
        }
      } else {
        return {
          locationId: this.testDriveData?.location?.id || this.testDriveData?.location
        }
      }
    },
    getLocationById(id) {
      return this.locations?.find(location => location.id === id)
    },
    isDateInRange(selectedDateInput, fromDateInput, toDateInput) {
      const selectedDate = new Date(selectedDateInput)
      const fromDate = new Date(fromDateInput)
      const toDate = new Date(toDateInput)
      return selectedDate >= fromDate && selectedDate <= toDate
    },
    formatTime(time) {
      const parts = time.split(':')
      const hours = (formattedPrefix + parts[0]).slice(-2)
      const minutes = (formattedPrefix + (parts[1] || 0)).slice(-2)
      return `${hours}:${minutes}`
    },
    handleChangeValue(event) {
      this.preferredLocation = this.getLocationById(event)?.testDriveRelocationPrice
    },
    imageUrl(path, size) {
      return `${import.meta.env.VITE_IMAGES_URL}/image/${size}x0/${path}.webp`
    },
    async loadDetails() {
      this.loading = true
      this.error = null
      try {
        const queries = [
          `targetSiteByClientId(id: "${window.seezSdk.clientId ?? 'mp'}") {
          urlConfig {
            termsAndConditionsUrl
            privacyPolicyUrl
          }
          customerSupport {
            phoneNumber
          }
        }`
        ]
        if (this.listingId) {
          queries.push(`
          listing(id: ${this.listingId}) {
            id
            name
            year
            referenceNumber
            targetSiteId
            images
            internalNote
            owner {
              descendants(type: showroom) {
                id
                name
                plainAddress
                testDriveRelocationPrice { value currency }
              }
            }
            locatedAt {
              id
              plainAddress
              phone
              testDriveRelocationPrice { value currency }
              openingHours {
                week { from to }
                testDriveTimeSlotSize
                holidays
              }
            }
          }
        `)
        }
        const result = await this.queryApi('{ ' + queries.join('\n') + ' }')
        if (!result.listing) {
          this.error.message = { message: 'Error loading listing' }
          return (this.loading = false)
        }

        this.details = { ...result.listing }
        this.targetSiteConfig = result.targetSiteByClientId

        if (result.listing.locatedAt.openingHours?.week?.length) {
          this.details.locatedAt.openingHours.week = result.listing.locatedAt.openingHours.week.map(item => {
            if (item) {
              return {
                from: this.formatTime(item.from),
                to: this.formatTime(item.to)
              }
            }
            return null
          })
          this.isOpeningHours = true
        } else {
          this.isOpeningHours = false
        }
        this.loading = false
      } catch (error) {
        this.error = { message: error }
        this.loading = false
      }
    },
    async submitTestDrive(paymentMethod) {
      this.emailError = ''
      if (!this.validateEmail()) return
      if (!this.testDriveData.termsPolicy) return
      if (this.errorQID) return
      if (!this.testDriveData.timeSlot) {
        this.errorTime = true
        return
      }

      if (this.source === this.sourceBarchetti && (!this.locationType || (this.locationType === 'onsite' && !this.reserveType))) {
        return
      }

      if (!this.testDriveData.region && this.source === this.sourceAam) {
        this.errorRegion = true
        return
      }

      if (this.locationType === 'preferred' && !this.testDriveData.location) {
        this.errorLocation = true
        return
      }

      this.errorTime = false
      this.errorLocation = false
      this.errorRegion = false
      this.error = null
      this.errorSubmitTestDrive = null
      this.loading = true

      const paymentNeeded = paymentMethod && this.sourceBarchetti === this.source && (this.reserveType === 'yes' || this.preferredLocation.value)

      if (paymentNeeded) {
        this.redirectingToPaymentProvider = true
      }

      const when = new Date(`${this.testDriveData.date}T${this.testDriveData.timeSlot}:00.000Z`).toISOString()

      const testDriveState = paymentNeeded ? 'draft' : 'pending'

      const variables = {
        payload: {
          listingId: this.details.id,
          when,
          firstName: this.testDriveData.firstName,
          middleName: this.testDriveData.middleName,
          lastName: this.testDriveData.lastName,
          email: this.testDriveData.email,
          phone: this.testDriveData.phoneNumber,
          postalCode: this.testDriveData.postalCode,
          ...(sourceBarchetti === this.source ? { state: testDriveState } : {}),
          ...this.renderPayload(this.locationType, this.reserveType),
          ...(this.testDriveData.qid && { nationalID: this.testDriveData.qid })
        }
      }
      if (this.source === this.sourceAam) {
        variables.payload.region = this.testDriveData.region
      }

      let testDriveId
      try {
        const data = await this.queryApi('mutation createTestDrive($payload:TestDriveInput!){createTestDrive(input:$payload){id}}', variables)
        /**
         * Charge fee logic will specific for barchetti only
         * due to the timeline and current coding organization I will put this logic directly inside the sdk
         * TODO: we will need to separate these kind of logic from the sdk and place them inside the target site
         */
        testDriveId = data.createTestDrive.id
      } catch (error) {
        console.log(error)
        this.errorSubmitTestDrive = 'errorCreatingTestDrive'
        return
      } finally {
        this.loading = false
      }

      if (paymentNeeded && testDriveId) {
        this.loading = true
        try {
          const paymentProductType = this.paymentMethods[paymentMethod]
          const isRelocation = this.testDriveData.location && this.details.locatedAt.id !== this.testDriveData.location
          const sourceType = `testDriveVehicleReservation${isRelocation ? 'AndRelocation' : ''}`
          const apiDomain = import.meta.env.VITE_API_URL?.replace('/api', '/scf/redirect')
          const barchettiDomain = this.redirectDomain || 'https://barchetti.seez.dev'
          const redirectTo = encodeURIComponent(`${barchettiDomain}/listing/${this.details.id}`)
          const redirectUrl = `${apiDomain}?sourceId=${testDriveId}&sourceType=${sourceType}&redirectTo=${redirectTo}`
          const variables = {
            testDriveId,
            paymentProductType: paymentProductType,
            paymentMethod: paymentMethod,
            redirectionURL: {
              urlPaymentOk: `${redirectUrl}${encodeURIComponent('?payment-status=success')}`,
              urlPaymentError: `${redirectUrl}${encodeURIComponent('?payment-status=error')}`,
              urlPaymentPending: `${redirectUrl}`,
              urlPaymentRejected: `${redirectUrl}${encodeURIComponent('?payment-status=rejected')}`
            }
          }

          const query = `
            mutation createTestDriveReservationSCFPayment(
              $testDriveId: ID!,
              $paymentProductType: SCFReservationPaymentProductTypes,
              $paymentMethod: SCFPaymentMethodV3,
              $redirectionURL: SCFRedirectionURL
              ) {
              createTestDriveReservationSCFPayment(
                testDriveId: $testDriveId,
                paymentProductType: $paymentProductType,
                paymentMethod: $paymentMethod
                paymentType: reservation
                transactionType: authorization,
                redirectionURL: $redirectionURL
              ) {
                id
                paymentInfo {
                  scf {
                    providerUrl
                  }
                }
              }
            }
          `
          const response = await this.queryApi(query, variables)
          const paymnentProviderUrl = response?.createTestDriveReservationSCFPayment?.paymentInfo?.scf?.providerUrl
          if (paymnentProviderUrl) {
            return window.location.replace(paymnentProviderUrl)
          }
        } catch (error) {
          console.log(error)
          this.errorSubmitTestDrive = 'errorInitiatingPayment'
          this.redirectingToPaymentProvider = false
          return
        } finally {
          this.loading = false
        }
      }

      this.$emit('submit', this.testDriveData)
      this.track('asc_cta_interaction', { old_name: 'test_drive_sent', element_text: 'submit test drive form', element_subtype: 'cta_button', element_type: 'button', testDrive: this.testDriveData })
      window.dispatchEvent(
        new CustomEvent('sentTestDrive', {
          detail: {
            testDriveData: this.testDriveData,
            details: this.details
          }
        })
      )

      if (this.testDriveData.marketingConsent !== this.hasMarketingAccepted) {
        this.onChangeMarketingConsent()
      }

      this.onSuccess = true
    },
    async onChangeMarketingConsent() {
      try {
        await this.queryApi(`mutation { changeMarketingPreferences(accepted:${this.testDriveData.marketingConsent}) }`)
      } catch (e) {
        console.log('Error updating marketing consent', e)
      }
    },
    async getUserData() {
      const user = await window.seezSdk.getCurrentUser(null, false)
      if (user) {
        const { me } = await this.queryApi('{me{firstName,middleName,lastName,nationalID,acceptedMarketingTerms, email,phone,address{postalCode}}}')
        this.hasMarketingAccepted = Boolean(me.acceptedMarketingTerms)

        this.testDriveData = {
          ...this.testDriveData,
          firstName: me.firstName,
          middleName: me.middleName,
          lastName: me.lastName,
          phoneNumber: me.phone,
          email: me.email,
          postalCode: me.address?.postalCode,
          qid: me.nationalID
        }
      }
    },
    chooseLocationType(type) {
      this.locationType = this.locationType !== type ? type : null
      this.reserveType = null
      this.preferredLocation = {
        value: '',
        currency: ''
      }
      this.testDriveData.location = null
    },
    chooseReserve(type) {
      this.reserveType = this.reserveType !== type ? type : null
    },
    locationClass(type) {
      return this.locationType === type ? 'radioSelection active' : 'radioSelection'
    },
    reserveClass(type) {
      return this.locationType === 'onsite' ? (this.reserveType === type ? 'radioSelection active' : 'radioSelection') : 'radioSelectionHidden'
    }
  }
}
</script>

<style lang="scss">
@import '../../base.scss';

.testDriveFormWrapper {
  @include theme;
  background-color: var(--background);
  position: relative;

  > div {
    text-align: start;
    margin: 0;
    padding: 1rem;
  }

  .loader-container {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      position: absolute;
      z-index: 99;
      top: 55%;
      font-weight: 700;
    }
  }

  .paymentOptions {
    display: flex;
    gap: 16px;
    margin-top: 2rem;
    button {
      border: none;
      border-radius: 4px;
      height: 49px;
      flex: 1;
      font-family: Raleway;
      font-weight: 700;
      font-size: 18px;
    }

    .paypal {
      background-color: rgba(246, 199, 87, 1);
    }
    .card {
      padding: 0 1.5rem;
      background-color: rgba(42, 48, 50, 1);
      color: var(--white);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .detail-showroom {
    display: flex;

    .image {
      width: 7.5rem;
      height: 5rem;
      border-radius: 0.375rem;
      margin-inline-end: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: lightgray 50% / cover no-repeat;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .infotext {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      gap: 15px;
    }
  }

  div > .headingText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid black;
    margin-block-end: 1rem;
    padding-block-end: 2rem;

    @media screen and (min-width: 40rem) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-block-end: 0;
    }

    > div > span {
      font-size: 0.9rem;

      @media screen and (min-width: 40rem) {
        margin: 0 0.5rem;
      }
    }

    > div > :nth-child(2) {
      background-color: #f5f5f5;
      padding: 0.3rem 1.2rem;
      border-radius: 15px;
      font-weight: 600;
      font-size: 12.8px;
      margin: 1rem;
      white-space: nowrap;

      > svg {
        padding-inline-end: 0.3rem;
      }
    }
  }

  div > .locationAndReservation {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    text-align: start;
    gap: 0.5rem;

    p,
    .dateAndTimeText,
    label {
      grid-column: span 2 / span 2;
    }

    .radioSelection {
      grid-column: span 1 / span 1;
      background-color: #f5f6f7;
      padding: 1.25rem;
      border-radius: 0.625rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.75rem;
      cursor: pointer;
      border: 2px solid #f5f6f7;

      @media screen and (max-width: 40rem) {
        grid-column: span 2 / span 2;
      }

      .selectionInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        gap: 0.25rem;

        :nth-child(2) {
          font-weight: 700;
        }
      }
      .selectionInfo.row {
        flex-direction: row;
        flex: 1;
      }
      .radio {
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid #1b2021;
        border-radius: 2rem;
        box-sizing: border-box;
      }
    }
    .radioSelection.active {
      border: 2px solid #009246;
      background-color: #cce9da;
      .radio {
        background-color: #009246;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        div {
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 2rem;
          background-color: #cce9da;
        }
      }
    }
    .radioSelectionHidden {
      display: none;
    }
  }

  div > .testDriveForm {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    text-align: start;
    gap: 0.5rem;

    & > * {
      grid-column: span 6 / span 6;
    }

    @media screen and (min-width: 40rem) {
      :nth-child(2),
      :nth-child(3),
      :nth-child(9),
      :nth-child(10) {
        grid-column: span 3 / span 3;
      }

      :nth-child(5),
      :nth-child(6),
      :nth-child(7) {
        grid-column: span 2 / span 2;
      }
    }

    > .multiselectLabel {
      .multiselect {
        position: relative;

        > button {
          width: 100%;
          box-sizing: initial;
          padding: 0.2em 0.1em;
          margin: 0;
          background: white;
          border: 1px solid black;
          border-radius: 0;
          font-family: sans-serif;
          color: black;

          > svg {
            position: absolute;
            inset-inline-end: 1em;
            inset-block-start: 0.6em;
            transition: transform 50ms;
          }
        }
      }
    }

    label[for='qid'] {
      display: none;
    }

    .inputError {
      border-color: red;
    }

    .fieldError {
      display: flex;
      align-items: center;
      margin: 0.5rem 0 0 0;
      font-size: 0.7rem;
      color: red;

      > svg {
        margin-inline-end: 0.5rem;

        path {
          fill: red;
        }
      }
    }

    > label {
      margin: 0.2rem 0;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 36rem) {
        input[type='date']:not(.has-value)::before {
          color: black;
          white-space: nowrap;
          content: attr(placeholder);
        }

        input[type='date']:required:invalid::-webkit-datetime-edit {
          color: transparent;
        }

        input[type='date']:focus::-webkit-datetime-edit {
          color: black !important;
          padding-inline-start: 0.5rem;
        }

        input[type='date']:focus:before {
          content: '';
        }
      }

      &.check {
        flex-direction: row;

        > input {
          width: min-content;
        }
      }
    }

    > .tradeInBtn {
      cursor: pointer;
      width: inherit;
      margin: 1rem 0 0 0;
      width: 100%;
    }

    > .submitError {
      color: red;
      text-align: center;
    }

    > .error {
      color: red;
      align-self: center;
      text-align: center;
      margin: 0;
    }

    > .termsWrapper {
      font-size: 10.4px;
      display: flex;
      flex-direction: column;
      text-align: start;

      > label {
        padding-inline-start: 0.2rem;
      }

      .error {
        outline: 1px solid red;
        border: none;
      }
    }
  }

  > .loader {
    position: absolute;
    inset: 0;
  }

  div > .info {
    max-width: 531px;
    text-align: start;
    border-top: 1px solid black;
    margin: 2rem 0 0 0;

    > p > a {
      color: black;
      font-family: inherit;
    }
  }

  > .success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .iconWrapper {
      padding: 0.5rem;
      background-color: #b3e1c2;
      border-radius: 999px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
    }

    > p {
      max-width: 511px;
      text-align: center;
    }

    > button {
      margin: 1rem 0;
      cursor: pointer;
    }
  }
}

.no-opening {
  margin: 1rem;
}
</style>
