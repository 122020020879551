<template>
  <div class="formContainer" :class="{ defaultStyles: loadDefaultStyles }">
    <SkeletonLoader v-if="loadingTemplate" />
    <form v-if="!onSuccess && !loadingTemplate" @submit.prevent="formSubmitted" class="dynamicInquiryForm" :class="{ defaultStyles: loadDefaultStyles }">
      <h2 v-if="t">{{ t[leadType]?.heading }}</h2>
      <ListingSelector v-if="selectedListingDetails" :listings-details="listingsDetails" :selected-listing-details="selectedListingDetails" @listing-selected="handleListingSelected" />
      <fieldset v-for="section in dynamicInquiryForm[0]?.fields" :key="section.fields" :class="`${section.name}Form`">
        <legend>
          {{ t[leadType]?.[section.name]?.legend }}
        </legend>
        <p v-if="section.errorMessage" class="leadTypeError">
          {{ t[section.errorMessage] || t.errorCreatingInquiry }}
        </p>
        <FormTemplate :translation-node="t" :preloaded-data="inquiryData" :fields="section.fields" @updated="updateInquiry" />
      </fieldset>
      <p class="error" v-if="errorMessage">{{ t[errorMessage] }}</p>
      <input data-test-id="inputSubmit" v-if="!onSuccess" type="submit" :value="t[leadType]?.submit" />
      <slot v-if="selectedListingDetails" name="contactInfo" class="contactInfo">
        <p class="contactInfo" v-html="handleContactInfo(t)" />
      </slot>
      <div v-if="loading || submitting" class="loaderWrapper">
        <Loader />
      </div>
    </form>
    <Success v-if="onSuccess" :translation-node="t" :lead-type="leadType" :read-only-mode="readOnlyMode" />
  </div>
</template>

<script>
import SeezSdk from '../../sdk'
import SkeletonLoader from './SkeletonLoader.ce.vue'
import { langMixin } from '../lang'
import FormTemplate from '../FormTemplate/FormTemplate.ce.vue'
import ListingSelector from './ListingSelector.ce.vue'
import Loader from '../Loader.ce.vue'
import Success from './Success.ce.vue'
import { analyticsMixin } from '@/analytics.js'
import { readOnlyModeMixin } from '../readOnlymode.js'
//This component like every component in SDK can receive styles by the mixin StylerMixin. Refer to main.js and check the logic around customCss field.
//If the styles applied are not what is shown on defaultStyles, that means that the styles are being applied by the target site config.
const errorTranslationKeys = {
  INQUIRY: 'errorCreatingInquiry',
  LISTING: 'errorListing',
  DEALERSHIP: 'errorDealershipLoad',
  HOLIDAYS: 'errorTestDriveHoliday',
  NO_LISTING: 'errorNoListing',
  SHOWROOM_CLOSED: 'errorShowroomClosed',
  SELECT_SHOWROOM: 'errorSelectLocation',
  NO_TIME_SLOTS: 'noTimeSlots'
}

// Implemented leadtypes are: generalInquiry, testDriveInquiry, partsInquiry, serviceInquiry

export default {
  name: 'DynamicInquiryForm',
  components: { FormTemplate, ListingSelector, Loader, Success, SkeletonLoader },
  mixins: [SeezSdk.vueQueryMixin, langMixin('DYNAMIC_INQUIRY_FORM_TRANSLATIONS'), analyticsMixin, readOnlyModeMixin],
  props: {
    id: { type: String, default: '' },
    dealershipId: { type: String, default: '' },
    listing: { type: String, default: '' },
    leadType: { type: String, default: '' }
  },
  emits: ['update'],
  data() {
    return {
      targetSite: null,
      loadDefaultStyles: false,
      submitting: false,
      errorMessage: '',
      onSuccess: false,
      listingsDetails: [],
      selectedListingDetails: null,
      inquiryData: {},
      formFields: null,
      legalTerms: {},
      payload: null,
      locationOptions: [],
      timeSlotsOptions: [],
      leadTypeErrorMessage: '',
      locatedAtOpeningHours: {},
      dynamicInquiryForm: [],
      fields: [],
      unavailableDatesArr: [],
      loadingTemplate: true, // Indicates initial data loading
      loading: false, // Indicates form submission loading
      businessUnitType: '',
      businessUnitTypeId: '',
      additionalAttributes: []
    }
  },
  computed: {
    hasAppendedListing() {
      return this.listing || this.id
    }
  },
  watch: {
    t() {}
  },
  mounted() {
    // readOnlyMode comes from readOnlyMixin and is set in the parent page or component that uses this component
    if (this.readOnlyMode) {
      this.loadingTemplate = false
      this.onSuccess = true
      return
    }

    this.businessUnitType = this.$parent?.businessUnitType
    this.businessUnitTypeId = this.$parent?.businessUnitTypeId
    this.fetchData()
    this.track('inquiry_form_shown', { type: this.leadType, listing: this.listing ?? null, externalId: this.id ?? null })
  },
  methods: {
    async fetchData() {
      this.loadingTemplate = true
      try {
        await this.loadTargetSite()
        // Handle external id case: if provided, override listing
        const listingId = this.id ? await this.queryListingByExternalId() : null
        await this.loadListings(listingId)
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingTemplate = false
      }
    },
    handleContactInfo(t) {
      const phone = this.selectedListingDetails?.dealership?.phone || this.targetSite?.customerSupport?.phoneNumber || ''
      const referenceNumber = this.selectedListingDetails?.referenceNumber || ''
      return t.disclaimer.replace('{phone_number}', phone).replace('{phone_number_label}', phone).replace('{reference_number}', referenceNumber)
    },

    handleLegalText(section) {
      return section.name === 'legal' ? this.t.legalText?.replace(/{dealershipName}/g, this.targetSite?.name) : ''
    },
    async queryListingByExternalId() {
      if (!this.id) return
      try {
        const { listingsByExternalId } = await this.queryApi(`{listingsByExternalId(externalId: "${this.id}")}`)
        if (listingsByExternalId.length === 0) {
          this.errorMessage = errorTranslationKeys.NO_LISTING
          this.loadingTemplate = false
          return
        }

        return listingsByExternalId[0]
      } catch (e) {
        console.error('Error queryListingByExternalId', e)
        this.errorMessage = errorTranslationKeys.LISTING
      }
    },
    //Arg listingId is passed to loadListings when the externalId is passed to the component
    async loadListings(listingId) {
      let ids = []
      if (listingId) {
        ids.push(listingId)
      } else {
        ids = this.listing.split(',').filter(i => i != '')
      }

      if (ids.length == 0) return

      try {
        const { listingsByIds } = await this.queryApi(
          `query listings($ids: [ID!]!) {
            listingsByIds(ids: $ids) {
              id
              name
              variant
              year
              images
              kilometrage
              kmUnit
              externalId
              referenceNumber
              vehicle {
                locatedAt {
                  id
                  name
                  plainAddress
                  children(type: department) { openingHours { testDriveTimeSlotSize holidays week { from to } } }
                  openingHours { testDriveTimeSlotSize holidays week {from to} }}
                 }
              dealership {
                id
                plainAddress
                phone
              }
              locatedAt {
              openingHours { testDriveTimeSlotSize holidays week { from to } } }
            }
          }`,
          { ids }
        )
        this.listingsDetails = listingsByIds
        this.selectedListingDetails = this.listingsDetails[0] ?? null
        this.inquiryData.location = this.listingsDetails[0]?.vehicle?.locatedAt?.id || ''

        this.locatedAtOpeningHours = this.listingsDetails[0]?.vehicle?.locatedAt?.openingHours || {}

        if (this.listingsDetails[0]?.vehicle?.locatedAt) {
          const showroom = this.listingsDetails[0].vehicle.locatedAt
          const openingHours = this.getOpeningHoursByRole([showroom])
          this.locatedAtOpeningHours = openingHours
          this.handleTimeSlotByConfig(this.inquiryData)
        }

        if (ids.length) {
          this.handleListingLocationOptions()
          this.setDropdownOptions(this.dynamicInquiryForm[0].fields)
        }
      } catch (e) {
        console.error('Error loadListing', e)
        this.errorMessage = errorTranslationKeys.LISTING
      }
    },
    getOpeningHoursByRole(dealershipsArr = []) {
      if (!dealershipsArr.length) return null
      // Helper: Check if a dealership has a specific role.
      const dealershipHasRole = (dealership, role) => {
        return dealership.customAttributes?.some(attr => {
          if (attr.key === 'roles' && attr.value) {
            return attr.value
              .split(',')
              .map(r => r.trim().toLowerCase())
              .includes(role)
          }
          return false
        })
      }
      // Helper: Recursively search for a dealership that matches the role and whose parentId equals selectedShowroomId.
      const deepFindDepartment = (dealerships, role, selectedShowroomId) => {
        for (const dealership of dealerships) {
          // Check if this dealership is a department with the proper role
          // AND its parentId matches the selected showroom id
          if (dealership.businessUnitType === 'department' && dealershipHasRole(dealership, role) && dealership.parentId === selectedShowroomId) {
            return dealership
          }
          // If there are children, search recursively
          if (dealership.children && dealership.children.length) {
            const found = deepFindDepartment(dealership.children, role, selectedShowroomId)
            if (found) return found
          }
        }
        return null
      }
      // Get the selected showroom from the inquiry data.
      const selectedShowroomId = this.inquiryData?.location
      const topDealership = dealershipsArr[0]
      // For fallback, get the descendants array.
      const descendants = topDealership?.descendants || []

      let selectedDealership = null
      if (this.leadType === 'testDriveInquiry' && this.hasAppendedListing) {
        // For testDrive, search first in immediate children for a sales department.
        const children = topDealership.children || []
        selectedDealership = children.find(d => dealershipHasRole(d, 'sales'))

        if (!selectedDealership) {
          selectedDealership = topDealership
        }
        // if no department with sales is found use the showroom instead
        return selectedDealership.openingHours || ''
      }

      if (this.leadType === 'testDriveInquiry' && !this.hasAppendedListing) {
        selectedDealership = deepFindDepartment(descendants, 'sales', selectedShowroomId)
        if (!selectedDealership) {
          selectedDealership = topDealership
        }
        // if no department with sales is found use the showroom instead
        return selectedDealership.openingHours || ''
      }

      if (this.leadType === 'generalInquiry') {
        // For generalInquiry, try a deep search among descendants for a sales department whose parent matches the showroom
        selectedDealership = deepFindDepartment(descendants, 'sales', selectedShowroomId)
        if (!selectedDealership) {
          // Fallback: look for a showroom in the descendants.
          selectedDealership = descendants.find(d => d.businessUnitType === 'showroom')
        }
        return selectedDealership?.openingHours || ''
      }

      if (this.leadType === 'partsInquiry') {
        selectedDealership = deepFindDepartment(descendants, 'parts', selectedShowroomId)
        if (!selectedDealership) {
          selectedDealership = descendants.find(d => d.businessUnitType === 'showroom')
        }
        return selectedDealership?.openingHours || ''
      }

      if (this.leadType === 'serviceInquiry') {
        selectedDealership = deepFindDepartment(descendants, 'service', selectedShowroomId)
        if (!selectedDealership) {
          selectedDealership = descendants.find(d => d.businessUnitType === 'showroom')
        }
        return selectedDealership?.openingHours || ''
      }

      // Default fallback if no matching leadType found.
      return { holidays: [], testDriveTimeSlotSize: 60, week: [] }
    },
    async loadTargetSite() {
      const query = `{
        currentTargetSite  {
          id
          name
          dealerships {
            id
            name
            plainAddress
            openingHours { testDriveTimeSlotSize holidays week { from to additionalSlots { from to } }}
            descendants(type: [showroom department]) { id parentId businessUnitType name plainAddress customAttributes { key value }  openingHours { testDriveTimeSlotSize holidays week { from to additionalSlots { from to } }} }
          }
          customerSupport { email phoneNumber }
          urlConfig { privacyPolicyUrl termsAndConditionsUrl }
          layoutTemplates(name: "${this.leadType}") {
            name
            fields {
              name
              fields {
                name
                type
                values
                area
                regex
                question
                infoLabel
                placeholder
                maxLength
                query
                required
                visible
                enabled
                startDateDelay
                default
                translationNode
                acceptedFiles
                lookupGeneratorFunction
              }
            }
          }
        }
      }`

      try {
        const { currentTargetSite } = await this.queryApi(query)

        if (!currentTargetSite) {
          this.errorMessage = errorTranslationKeys.DEALERSHIP
          return
        }
        this.targetSite = currentTargetSite

        if (!this.selectedListingDetails) {
          this.locationOptions = this.handleLocation(currentTargetSite.dealerships)
        }
        if (currentTargetSite.layoutTemplates.length > 1) {
          this.dynamicInquiryForm = currentTargetSite.layoutTemplates.filter(template => template.name === 'generalInquiry')
          throw new Error('Type passed not valid')
        }

        const unavailableDatesArr = currentTargetSite?.dealerships[0]?.openingHours?.holidays || []
        if (unavailableDatesArr?.length) this.unavailableDatesArr = unavailableDatesArr

        this.dynamicInquiryForm = currentTargetSite.layoutTemplates
        this.additionalAttributes = this.findFieldsWithAdditionalAttributes(currentTargetSite.layoutTemplates)

        this.fields = this.dynamicInquiryForm[0]?.fields
        this.setDropdownOptions(this.dynamicInquiryForm[0].fields)

        //if no listing is appended. When there is a listing appended the logic to extract time slot should come from locatedAt inside a listing.vehicle on query listing
        if (currentTargetSite.dealerships.length > 0 && !this.listing && !this.id) {
          this.handleTimeSlotByConfig(this.inquiryData)
        }
      } catch (e) {
        console.error('Error loadTargetSite', e)
        this.errorMessage = errorTranslationKeys.DEALERSHIP
      }
    },
    findFieldsWithAdditionalAttributes(data) {
      let result = []

      function searchFields(fields) {
        for (const field of fields) {
          if (field.default === 'additionalAttribute') {
            result.push(field.name)
          }
          if (field.fields && Array.isArray(field.fields)) {
            searchFields(field.fields) // Recursively search in nested fields
          }
        }
      }

      for (const item of data) {
        if (item.fields && Array.isArray(item.fields)) {
          searchFields(item.fields)
        }
      }

      return result
    },
    setDropdownOptions(fields) {
      if (fields.length === 0) return
      fields.forEach(field => {
        if (field.name === 'location' && this.locationOptions.length) {
          field.values = this.locationOptions
        }
        if (field.fields && Array.isArray(field.fields)) {
          this.setDropdownOptions(field.fields)
        }
        if (field.name === 'time' && this.timeSlotsOptions.length) {
          field.values = this.timeSlotsOptions
        }
        if (field.name === 'date' && this.unavailableDatesArr.length) {
          field.disabledDates = this.unavailableDatesArr
          field.disabledDatesLabel = this.t.closedDueHolidayDate
        }
      })
    },
    // To detect errors on the disabled dates fields.
    getInvalidDateFields(formStructure) {
      const invalidDateFields = []

      formStructure.forEach(section => {
        const { name: sectionName, fields } = section
        if (Array.isArray(fields)) {
          fields.forEach(field => {
            if (field.type === 'date' && field.invalid === true) {
              invalidDateFields.push({
                section: sectionName,
                fieldName: field.name,
                invalid: field.invalid,
                disabledDates: field.disabledDates || [],
                disabledDatesLabel: field.disabledDatesLabel || ''
              })
            }
          })
        }
      })

      return invalidDateFields
    },
    findFileUploadRecursive(data, results = []) {
      if (Array.isArray(data)) {
        data.forEach(item => this.findFileUploadRecursive(item, results))
      } else if (data && typeof data === 'object') {
        if (data.type === 'fileUpload') {
          results.push(data)
        }
        Object.values(data).forEach(value => {
          if (typeof value === 'object' && value !== null) {
            this.findFileUploadRecursive(value, results)
          }
        })
      }
      return results
    },
    async handleFileUpload(files) {
      this.loading = true
      const uniqueId = crypto.randomUUID()
      const fileUploadFields = this.findFileUploadRecursive(this.fields)
      if (!fileUploadFields?.length) return

      try {
        const uploadPromises = fileUploadFields.map(async (field, index) => {
          const file = files[index]
          if (!file) return null

          const path = field.default
          const response = await window.seezSdk.uploadFile(`${path}/${uniqueId}`, file, false, false)

          return {
            //TODO: need to remove this from here and add it as a new field to the template. Which field ?
            type: 'driving_license_front',
            path: response,
            name: file.name,
            fileType: file.type
          }
        })

        const uploadedDocuments = await Promise.all(uploadPromises)
        return uploadedDocuments.filter(doc => doc !== null)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async formSubmitted() {
      const invalidFields = this.getInvalidDateFields(this.dynamicInquiryForm[0].fields)
      if (invalidFields.length) {
        this.errorMessage = errorTranslationKeys.SHOWROOM_CLOSED
        return
      }
      this.loading = true
      this.errorMessage = ''
      this.onSuccess = false

      if (this.inquiryData.date) {
        this.inquiryData.date = this.inquiryData.date.split('T')[0]
      }

      if (this.inquiryData?.files) {
        if (this.inquiryData.files.length === 0) {
          delete this.inquiryData.files
        } else {
          const docs = await this.handleFileUpload(this.inquiryData.files)
          if (docs.length) {
            this.inquiryData.files = JSON.stringify(docs)
          }
        }
      }

      const formattedFields = this.formatPayload(this.inquiryData)

      const payload = {
        inquiryType: this.leadType,
        dealershipId: +this.dealershipId || +this.targetSite?.dealerships[0]?.id,
        inquiryData: formattedFields
      }

      if (this.selectedListingDetails) {
        payload.listingId = this.selectedListingDetails.id
      }

      if (typeof this.$parent?.getAdditionalTrackingProps === 'function') {
        const { chatReferenceId } = this.$parent.getAdditionalTrackingProps()

        if (chatReferenceId) {
          payload.chatReference = chatReferenceId
        }
      }

      const query = `
        mutation createInquiry($inquiryType: String!, $dealershipId: ID, $listingId: ID, $inquiryData: [CustomAttributeInput!]!, $chatReference: String) {
          createInquiry(
            inquiryType: $inquiryType
            dealershipId: $dealershipId
            listingId: $listingId
            inquiryData: $inquiryData
            chatReference: $chatReference
          ) {
            id
            inquiryData {
              key
              value
            }
            inquiryType
          }
        }`

      try {
        const { createInquiry } = await this.queryApi(query, payload)

        if (!createInquiry) {
          this.errorMessage = errorTranslationKeys.INQUIRY
        } else {
          this.onSuccess = true
          this.inquiryData = {}
          this.track('inquiry_form_submitted', { type: this.leadType, listing: this.listing ?? null, externalId: this.id ?? null })
        }
      } catch (e) {
        console.log(e)
        this.errorMessage = errorTranslationKeys.INQUIRY
        this.track('inquiry_form_error_sending', { type: this.leadType, listing: this.listing ?? null, externalId: this.id ?? null })
      } finally {
        this.loading = false
      }
    },
    handleTimeSlotByConfig(newData) {
      const { date } = newData
      const localDate = date || new Date()
      const dayOfWeekIndex = new Date(localDate).getDay()
      const openingHours = this.leadType === 'testDriveInquiry' && this.hasAppendedListing ? this.locatedAtOpeningHours : this.getOpeningHoursByRole(this.targetSite?.dealerships)
      if (!openingHours) return
      const openingHoursOnThisDay = openingHours?.week[dayOfWeekIndex] || []
      const slotSizeInMinutes = openingHours?.testDriveTimeSlotSize
      const { from, to } = openingHoursOnThisDay
      const additionalSlots = openingHoursOnThisDay?.additionalSlots || []
      const activeTimeRanges = []

      if (from && to) {
        activeTimeRanges.push({ from, to })
      } else {
        activeTimeRanges.push({ from: null, to: null })

        if (!this.unavailableDatesArr.includes(date)) {
          this.unavailableDatesArr = [...this.unavailableDatesArr, date]
        }

        this.setDropdownOptions(this.dynamicInquiryForm[0].fields)
      }

      if (Array.isArray(additionalSlots)) {
        additionalSlots.forEach((slot, index) => {
          if (slot.from && slot.to) {
            activeTimeRanges.push({ from: slot.from, to: slot.to })
          } else {
            console.warn(`Invalid additional slot at index ${index}:`, slot)
          }
        })
      }

      let combinedTimeSlots = []
      activeTimeRanges.forEach(range => {
        const slots = this.generateTimeSlots(slotSizeInMinutes, range.from, range.to)
        combinedTimeSlots = combinedTimeSlots.concat(slots)
      })

      combinedTimeSlots = [...new Set(combinedTimeSlots)]
      const [{ from: activeFrom, to: activeTo }] = activeTimeRanges

      if (activeFrom && activeTo) {
        combinedTimeSlots = this.convertSlotsToFormat(combinedTimeSlots, from, to)
      }

      this.timeSlotsOptions = combinedTimeSlots
      this.setDropdownOptions(this.dynamicInquiryForm[0].fields)
      return combinedTimeSlots
    },
    handleLocationChange() {
      let newOpeningHours = null
      if (this.leadType === 'testDriveInquiry' && this.hasAppendedListing) {
        newOpeningHours = this.locatedAtOpeningHours
      } else {
        newOpeningHours = this.getOpeningHoursByRole(this.targetSite?.dealerships) || {}
      }
      this.locatedAtOpeningHours = newOpeningHours

      if (newOpeningHours?.week && newOpeningHours.week.length) {
        const newSlots = this.handleTimeSlotByConfig(this.inquiryData)

        if (this.inquiryData.time && !newSlots.includes(this.inquiryData.time)) {
          this.inquiryData.time = ''
          this.errorMessage = 'The selected time is not available for the new location. Please select a new date/time.'
        } else {
          this.errorMessage = ''
        }
      }
    },
    updateInquiry(newData) {
      const key = Object.keys(newData)
      if (key.includes('date')) {
        this.selectedDate = newData
        this.handleTimeSlotByConfig(newData)
      }

      this.leadTypeErrorMessage = ''
      this.inquiryData = { ...this.inquiryData, ...newData }

      if (key.includes('location')) {
        this.handleLocationChange()
      }
    },
    handleListingSelected(listing) {
      this.selectedListingDetails = listing
      this.inquiryData.date = ''
      this.handleListingLocationOptions()
      this.inquiryData = { ...this.inquiryData, location: listing?.vehicle?.locatedAt?.id || '' }
      this.locatedAtOpeningHours = listing?.vehicle?.locatedAt?.openingHours
      this.handleTimeSlotByConfig(this.inquiryData)
    },
    handleListingLocationOptions() {
      const option = [
        {
          id: this.selectedListingDetails?.vehicle?.locatedAt?.id,
          value: `${this.selectedListingDetails?.vehicle?.locatedAt?.name} - ${this.selectedListingDetails?.vehicle?.locatedAt?.plainAddress}`
        }
      ]
      this.locationOptions = option
    },
    handleLocation(dealershipArr = []) {
      const handleFilteredLocations = d => {
        let filtered = d.flatMap(d => d.descendants).filter(s => s.plainAddress && s.plainAddress.trim() !== '') // Ensure address is present

        if (this.leadType === 'testDriveInquiry') {
          //Identify departments with the 'sales' role
          const salesDepartments = filtered.filter(dept => dept.businessUnitType === 'department' && dept.customAttributes.some(a => a.key === 'roles' && a.value?.split(',').includes('sales')))
          //Get unique parent showroom IDs from these departments
          const showroomIds = [...new Set(salesDepartments.map(dept => dept.parentId))]
          if (showroomIds.length > 0) {
            //If showroomIds are found, filter showrooms that have these IDs
            filtered = d.flatMap(d => d.descendants).filter(d => d.businessUnitType === 'showroom' && showroomIds.includes(d.id))
          } else {
            // If no showroomIds found, return all showrooms
            filtered = d.flatMap(d => d.descendants).filter(d => d.businessUnitType === 'showroom')
          }
        } else {
          if (['serviceInquiry', 'partsInquiry'].includes(this.leadType)) {
            const role = this.leadType === 'serviceInquiry' ? 'service' : 'parts'
            filtered = filtered.filter(s => s.customAttributes.some(a => a.key === 'roles' && a.value?.split(',').includes(role)))
          }
          // Filter for franchises
          if (this.businessUnitType === 'franchise') {
            filtered = filtered.filter(s => s.businessUnitType === 'showroom' && s.parentId === this.businessUnitTypeId)
          }
        }
        return filtered
          .map(s => ({ id: s.id, value: `${s.name} - ${s.plainAddress.trim()}` }))
          .sort((a, b) => a.value.localeCompare(b.value))
          .filter((option, index, self) => index === self.findIndex(o => o.value === option.value))
      }
      this.locationOptions = handleFilteredLocations(dealershipArr)
      this.setDropdownOptions(this.fields)
      return this.locationOptions
    },
    formatPayload(obj) {
      const keyValueArray = []
      const additionalAttributes = []

      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          const nestedArray = this.formatPayload(value)
          nestedArray.forEach(item => {
            keyValueArray.push({
              key: `${key}.${item.key}`,
              value: this.sanitize(String(item.value))
            })
          })
        } else {
          let finalValue = String(value)
          if (/(am|pm)$/i.test(finalValue.trim())) {
            finalValue = this.to24HourFormat(finalValue)
          }

          if (this.additionalAttributes.includes(key)) {
            additionalAttributes.push({
              key: key,
              value: this.sanitize(finalValue)
            })
          } else {
            keyValueArray.push({
              key,
              value: this.sanitize(finalValue)
            })
          }
        }
      }
      if (additionalAttributes.length > 0) {
        keyValueArray.push({
          key: 'additionalAttributes',
          value: JSON.stringify(additionalAttributes)
        })
      }

      return keyValueArray
    },
    sanitize(str) {
      // Create a temporary DOM element to leverage the browser's parser
      const tempDiv = document.createElement('div')
      tempDiv.textContent = str
      return tempDiv.innerHTML
    },
    convertSlotsToFormat(slots, from, to) {
      return slots.map(slot => {
        const mins = this.timeToMinutes(slot)
        return this.minutesToTime(mins, from, to)
      })
    },
    to24HourFormat(timeStr) {
      timeStr = timeStr.trim().toLowerCase()
      const ampmRegex = /^(1[0-2]|0?\d):([0-5]\d)(am|pm)$/
      const match = timeStr.match(ampmRegex)

      if (!match) {
        return timeStr
      }

      let hours = parseInt(match[1], 10)
      const minutes = match[2]
      const period = match[3]

      if (period === 'pm' && hours !== 12) hours += 12
      if (period === 'am' && hours === 12) hours = 0

      return `${hours.toString().padStart(2, '0')}:${minutes}`
    },
    timeToMinutes(timeStr) {
      timeStr = timeStr.trim().toLowerCase()
      const ampmRegex = /^(1[0-2]|0?[1-9]):([0-5]\d)\s*(am|pm)$/i
      const ampmMatch = timeStr.match(ampmRegex)

      if (ampmMatch) {
        let hours = parseInt(ampmMatch[1], 10)
        const minutes = parseInt(ampmMatch[2], 10)
        const period = ampmMatch[3].toLowerCase()
        if (period === 'pm' && hours !== 12) hours += 12
        if (period === 'am' && hours === 12) hours = 0
        return hours * 60 + minutes
      }

      const twentyFourHourRegex = /^([01]?\d|2[0-3]):([0-5]\d)$/
      const twentyFourMatch = timeStr.match(twentyFourHourRegex)

      if (twentyFourMatch) {
        const hours = parseInt(twentyFourMatch[1], 10)
        const minutes = parseInt(twentyFourMatch[2], 10)
        return hours * 60 + minutes
      }

      console.error(`Invalid time format: "${timeStr}". Use "HH:MM" or "HH:MMam/pm".`)
    },
    minutesToTime(totalMinutes, from, to) {
      const isAmPmFormat = /am|pm$/i.test(from.trim().toLowerCase()) || /am|pm$/i.test(to.trim().toLowerCase())
      let hours = Math.floor(totalMinutes / 60)
      const minutes = totalMinutes % 60

      if (isAmPmFormat) {
        const suffix = hours >= 12 ? 'pm' : 'am'
        let displayHour = hours % 12
        if (displayHour === 0) displayHour = 12
        return `${displayHour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${suffix}`
      } else {
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
      }
    },
    generateTimeSlots(slotSize, sT, eT) {
      let startTime = sT
      let endTime = eT
      if (!sT && !eT) {
        const is12HourFormat = () => {
          const formatter = new Intl.DateTimeFormat(this.locale, { hour: 'numeric' })
          // If formatter parts contain 'dayperiod', it's a 12-hour format
          return formatter.formatToParts(new Date(Date.UTC(2020, 0, 1, 13))).some(part => part.type === 'dayPeriod')
        }

        startTime = is12HourFormat() ? '09:00 AM' : '09:00'
        endTime = is12HourFormat() ? '05:30 PM' : '17:30'
      }

      let slotSizeInMinutes = slotSize
      if (!slotSizeInMinutes || typeof slotSizeInMinutes !== 'number' || slotSizeInMinutes < 15 || slotSizeInMinutes > 90 || slotSizeInMinutes % 15 !== 0) {
        slotSizeInMinutes = 60
      }

      const startTimeLC = startTime.toLowerCase().trim()
      const endTimeLC = endTime.toLowerCase().trim()

      const startMinutes = this.timeToMinutes(startTime)
      const endMinutes = this.timeToMinutes(endTime)

      const slots = []
      let current = startMinutes

      while (current <= endMinutes) {
        slots.push(this.minutesToTime(current, startTimeLC, endTimeLC))
        current += slotSizeInMinutes
      }

      if (slots.length > 0 && this.timeToMinutes(slots[slots.length - 1]) > endMinutes) {
        slots.pop()
      }

      return slots
    }
  }
}
</script>

<style>
.formContainer {
  position: relative;
}

.dynamicInquiryForm {
  height: auto;
  background-color: var(--chat-bg-color, white);

  --highlight: #0068ff;
  --text-color: black;
  --background: white;
  --accented-background: color-mix(in oklab, var(--highlight) 5%, var(--background));
  --base-font: Biennale, Verdana, Geneva, Tahoma, sans-serif;
  --base-shadow: 0 0 0.25em rgba(0, 0, 0, 0.1);
  --border-color: #ccc;

  .formTemplate input[type='checkbox'] {
    transform: scale(1) !important;
    @media screen and (max-width: 50rem) {
      transform: scale(0.9) !important;
    }
  }
}

.leadTypeError {
  color: red;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 0.7rem;
}

.defaultPrivacy {
  margin-bottom: 1rem;
}

.defaultPrivacy p {
  font-size: 0.875rem;
}

.success {
  border-radius: 52px;
  color: black;
  padding: 12px 0;

  width: 100%;
  margin: auto;
  text-align: center;
  border: none;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 600;
}

.success p {
  margin: 0;
}

.checkboxWrapper {
  display: flex;
  flex-direction: row !important;
  align-items: baseline;
  justify-content: start;

  h2 {
    margin: 0 !important;
  }
}

.loaderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* remove styles from dropdown multilselect */
.multiselect ul > li span {
  font-size: 0.875rem;
  background-color: transparent !important;
  border-radius: none !important;
}

.error {
  color: red;
  font-size: 0.8rem;
  text-align: center;
}

.dynamicInquiryForm,
.SkeletonLoader {
  position: relative;
}

.SkeletonLoader {
  z-index: 5;
}

.loaderWrapper {
  z-index: 10;
}

.defaultStyles {
  .formContainer {
    gap: 0.75rem;
  }

  h2 {
    margin: 0;
    max-height: 2rem;
    padding-inline-start: 0.2rem;
    margin-bottom: 0.5rem;
  }

  .contactInfo {
    display: none;
  }

  *:not(h1, h2) {
    font-size: 1rem !important;
    @media screen and (min-width: 50rem) {
      font-size: 0.8rem !important;
    }
  }

  .listingSelector {
    position: relative;
    color: black;

    img {
      width: 6.5rem;
      border-top-left-radius: var(--chat-border-radius, 6px);
      border-bottom-left-radius: var(--chat-border-radius, 6px);
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: url(<path-to-image>) lightgray -18.713px -10.289px / 139.13% 120.229% no-repeat;
    }

    span:first-of-type {
      font-size: 1rem;
      font-weight: 700;

      @media screen and (min-width: 50rem) {
        font-size: 1.25rem;
      }
    }

    span:last-of-type {
      font-size: 0.7rem;
      font-weight: 600;

      @media screen and (min-width: 50rem) {
        font-size: 0.875rem;
      }
    }

    article {
      border-radius: var(--chat-border-radius, 6px);
      border: 1px solid var(--border-color);

      .chevronIcon {
        padding-inline-end: 0.5rem;
      }

      &:not(.expansible) {
        pointer-events: none;

        img {
          border-top-left-radius: var(--chat-border-radius, 6px);
          border-bottom-left-radius: var(--chat-border-radius, 6px);
        }
      }
    }

    .expansible {
      box-shadow: 0px 0px 20px 2px rgba(226, 226, 226, 0.5);
      background: #f6f6f6;

      img {
        width: 5rem;
        border-top-left-radius: var(--chat-border-radius, 6px);
        border-bottom-left-radius: var(--chat-border-radius, 6px);
        background: url(<path-to-image>) lightgray -18.713px -10.289px / 139.13% 120.229% no-repeat;

        @media screen and (min-width: 50rem) {
          width: 6.5rem;
        }
      }

      span:first-of-type {
        font-size: 0.8rem;
        font-weight: 700;

        @media screen and (min-width: 50rem) {
          font-size: 1.25rem;
        }
      }

      span:last-of-type {
        font-size: 0.675rem;
        font-weight: 600;

        @media screen and (min-width: 50rem) {
          font-size: 0.875rem;
        }
      }
    }

    ul {
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 20px 2px rgba(226, 226, 226, 0.5);
      list-style: none;
      width: -webkit-fill-available;
      height: 21rem;
      overflow: scroll;
      padding: 0.5rem;
      z-index: 2;

      li {
        width: 100%;

        article {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          display: grid;
          gap: 0.25rem 1rem;
          grid-template-columns: auto 1fr;
          grid-template-rows: 1fr 1fr;
          grid-template-areas: 'image title' 'image subtitle';

          input {
            right: v-bind('textDirection === "rtl" ? "auto" : "1rem"');
            left: v-bind('textDirection === "rtl"  ? "1rem" : "auto"');
          }

          img {
            grid-area: image;
            width: 5rem;
            height: 4.25rem;
            border-top-left-radius: var(--chat-border-radius, 6px);
            border-bottom-left-radius: var(--chat-border-radius, 6px);

            background: url(<path-to-image>) lightgray -18.713px -10.289px / 139.13% 120.229% no-repeat;
            object-fit: cover;

            @media screen and (min-width: 50rem) {
              width: 6.5rem;
            }
          }

          span:first-of-type {
            grid-area: title;
            align-self: flex-end;
            flex-wrap: wrap;
            font-size: 0.6rem;
            font-weight: 700;

            @media screen and (min-width: 50rem) {
              font-size: 1.25rem;
            }
          }

          span:last-of-type {
            grid-area: subtitle;
            align-self: flex-start;
            font-size: 0.6rem;
            font-weight: 600;

            @media screen and (min-width: 50rem) {
              font-size: 0.875rem;
            }
          }
        }

        .selected {
          opacity: 0.7;
          border: 1px solid var(--highlight);
          border-radius: 6px;
        }
      }
    }
  }

  .dynamicInquiryForm {
    padding: 0.8rem;
    border-radius: var(--chat-border-radius, 0.563rem);
    border: 1px solid #ccc;

    fieldset {
      border: none;
      padding: 0;
      display: flex;
      flex-direction: row-reverse;

      legend {
        font-weight: 500;
        /* margin: 0 0 0.75rem 0; */
        max-height: 18px;
        margin-bottom: 12px;
      }
    }

    fieldset:not(:first-of-type):not(:last-of-type) {
      margin-top: 1rem;
    }

    fieldset:last-of-type {
      margin-top: 0.5rem;
    }

    .search-select {
      position: relative;

      .options-list li {
        cursor: pointer;
        margin: 0.5rem 0;
      }

      .options-list li.highlighted {
        background: var(--highlight);
        color: white;
      }

      .not-found {
        color: red;
        font-size: 0.8rem;
        text-align: start;
        margin-top: 0.5rem;
      }

      .input-container {
        width: 100%;
        display: flex;

        .chevron-down {
          position: absolute;
          right: v-bind('textDirection === "rtl" ? "auto" : "0.5rem"');
          left: v-bind('textDirection === "rtl"  ? "0.5rem" : "auto"');
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.5rem;
          color: #ccc;
          background-color: transparent;
          border: none;
          transition: transform 0.2s ease;
          cursor: pointer;
        }

        .rotate-up {
          transform: translateY(-40%) rotate(180deg);
        }

        .clear-button {
          position: absolute;
          top: 0.3rem;
          right: 0.5rem;
          width: 2rem;
          height: 2rem;
          padding: 0.3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          border: none;
          cursor: pointer;
          color: #ccc;
          right: v-bind('textDirection === "rtl" ? "auto" : "0.5rem"');
          left: v-bind('textDirection === "rtl"  ? "0.5rem" : "auto"');

          &.error {
            color: red;
          }
        }

        .clear-button:hover {
          color: #000;
        }
      }

      input {
        font-size: 1rem;
        padding: 0.9rem 1rem;
        border-radius: 0.5rem;
        width: 100%;
        border: 1px solid #e2e2e2;
        text-align: start;

        &.error {
          border: 1px solid red;

          &:focus {
            outline: none;
          }
        }
      }
      ul {
        list-style: none;
        position: absolute;
        border-radius: var(--chat-border-radius, 0.563rem);
        top: 2.5rem;
        left: 0;
        right: 0;
        height: auto;
        max-height: 15rem;
        min-height: 1rem;
        overflow: auto;
        background-color: white;
        padding: 1rem;
        z-index: 999;
        border: 1px solid #e2e2e2;

        li {
          cursor: pointer;
          padding: 0.2rem 0;

          &:hover {
            opacity: 0.7;
            color: var(--accent);
          }
        }
      }
    }

    .dynamicInputField label > span,
    .dateTime > span {
      display: none;
    }

    .formTemplate {
      width: -webkit-fill-available;

      input,
      textarea {
        padding: 0.72rem 1rem;
        border: 1px solid #ccc;
        border-radius: var(--chat-border-radius, 0.563rem);
        display: flex;
        align-items: center;
        font-size: 1rem;
        width: -webkit-fill-available;
      }

      input.inputDateTimeSlot {
        padding: 0.657rem 1rem;
      }

      .dateTime svg {
        width: 1rem;
      }

      .description {
        margin-top: 0.5rem;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 0.2rem;
        svg {
          width: 1rem;
          min-width: 1rem;
          margin-inline-end: 0.1rem;
        }
      }

      textarea {
        width: -webkit-fill-available !important;
      }

      input[type='checkbox'] {
        width: 10px;
        margin-inline-end: 0.5rem;
        margin-inline-start: 0;
        @media screen and (max-width: 50rem) {
          transform: scale(0.8); /* Adjust the scale as needed */
        }
      }

      input[name='privacyTerms'] {
        width: 10px;
        display: none;
        @media screen and (max-width: 50rem) {
          transform: scale(0.8); /* Adjust the scale as needed */
        }
      }

      .checkboxWrapper input[name='privacyTerms'] + div {
        font-weight: 700;
      }

      button {
        padding: 0.72rem 1rem;
        border: 1px solid #ccc;
        border-radius: var(--chat-border-radius, 0.563rem);
        display: flex;
        align-items: center;
        font-size: 1rem;

        span {
          display: contents;
        }
      }
    }

    input[type='submit'] {
      background-color: var(--primary-color, var(--highlight));
      color: var(--cta-text-color, --text-color);
      border-radius: var(--chat-border-radius, 0.563rem);
      width: -webkit-fill-available;
      margin-top: 0.563rem;
      padding: 0.75rem 0;
      border: none;
      cursor: pointer;
      font-weight: 600;
      white-space: normal;
    }
    input[type='submit']:hover {
      opacity: 0.9;
    }
  }
}
</style>
