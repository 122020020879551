const currencyCorrections = { 'dk': 'DKK', 'kr': 'DKK', 'kr.': 'DKK', '$': 'USD', '€': 'EUR' } //this is temporal until the backend sends the proper currency code
const currencyToSymbol = { 'EUR': '€', 'DKK': 'kr.' }
// const iso4217 = new Set(Intl.supportedValuesOf('currency'))
const supportedLocales = new Set(['en-US', 'de-CH'])

export const langMixin = compName => ({
  props: { lg: { type: String, default: null } }, //lang is a reserved attribute name
  data() {
    return {
      languageAsset: null
    }
  },
  computed: {
    locale() {
      //scales components tree
      let c = this
      while (c != null) {
        if (c.lg) return c.lg.replace(/_/g, '-')
        c = c.$parent
      }

      //scale tags tree
      let t = this.$el ?? document.body
      while (t != null) {
        if (t.getAttribute?.('lang')) return t.getAttribute('lang').replace(/_/g, '-')
        t = t.parentElement
      }
      return 'en'
    },
    language() {
      return this.locale.substring(0, 2)
    },
    languageResources() {
      if (this.languageAsset == null) {
        let l = this.language

        if (supportedLocales.has(this.locale)) l = this.locale
        else if (!['da', 'en', 'it', 'ar', 'de', 'es', 'sv', 'nl', 'fi', 'el', 'pt', 'uk', 'sl', 'fr'].includes(this.language)) {
          console.warn(`Language resources for ${l} not found. Falling back to english`)
          l = 'en'
        }
        window.seezSdk.loadLanguageResources(l).then(r => (this.languageAsset = r))
      }
      return this.languageAsset
    },
    t() {
      return this.languageResources?.[compName] ?? {}
    },
    textDirection() {
      // const locale = new Intl.Locale(this.language)
      return this.language === 'ar' ? 'rtl' : 'ltr'
    }
  },
  methods: {
    getCurrency(price, fallbackCurrency) {
      const cc = price.currency ?? fallbackCurrency
      const result = currencyCorrections[cc] ?? cc
      return result || null
    },
    formatPrice(price, fallbackCurrency) {
      const currency = this.getCurrency(price, fallbackCurrency)
      if (currency == null) return null
      return this.formatNumber(price.value, 0, currency)
    },
    formatNumber(number, decimals, currency) {
      if (number == null) return ''
      const options = {}
      if (decimals != null) Object.assign(options, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
      if (currency != null) Object.assign(options, { style: 'currency', currency, currencyDisplay: 'narrowSymbol' })
      return new Intl.NumberFormat(this.locale, options).format(parseFloat(number))
    },
    formatDate(date) {
      //, includeTime = false
      const unixTime = typeof date === 'number' ? date : Date.parse(date)
      if (isNaN(unixTime)) return null
      return new Intl.DateTimeFormat(this.locale).format(unixTime)
    },
    getSymbol(currency) {
      return currencyToSymbol[currency] || currency
    }
  }
})
